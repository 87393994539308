import React from "react";
import { Box, Typography, Button, Card } from "@mui/material";

function Payment(props) {
  const { link, price, tier } = props;

  const handleSubscribe = () => {
    const width = window.innerWidth * 0.8;
    const height = window.innerHeight * 0.95;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;
    const features = `width=${width},height=${height},left=${left},top=${top},noopener,noreferrer`;
    props.setUserClickedSubscribe(true);
    window.open(link, "", features);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Card
        sx={{
          px: 10,
          py: 5,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography variant="h6">{tier}</Typography>
        <Box
          sx={{
            display: "flex",
            gap: 1,
          }}
        >
          <Typography variant="h4">${price}</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="body1" sx={{ fontSize: "0.8rem" }}>
              per
            </Typography>
            <Typography variant="body1" sx={{ fontSize: "0.8rem" }}>
              month
            </Typography>
          </Box>
        </Box>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          width="100%"
          onClick={handleSubscribe}
        >
          Subscribe
        </Button>
      </Card>
    </Box>
  );
}

export default Payment;
