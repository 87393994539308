import { createSlice } from "@reduxjs/toolkit";

const mapSlice = createSlice({
  name: "map",
  initialState: {
    center: {
      lat: 40.58526,
      lng: -105.08442,
    },
    bounds: [0, 0, 0, 0],
    zoom: 4,
  },
  reducers: {
    setCenter: (state, action) => {
      state.center = action.payload.center;
    },
    setZoom: (state, action) => {
      state.zoom = action.payload.zoom;
    },
    setBounds: (state, action) => {
      state.bounds = action.payload.bounds;
    },
  },
});

export const { setCenter, setZoom, setBounds } = mapSlice.actions;
export const getCenter = (state) => state.map.center;
export const getZoom = (state) => state.map.zoom;
export const getBounds = (state) => state.map.bounds;

export default mapSlice.reducer;
