import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Stack,
  Card,
  IconButton,
  InputAdornment,
} from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import store from "../../redux/store";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setAuthenticated,
  setFavoritedLeads,
  setUserId,
  setPremiumLeads,
  setEmail,
  setPurchasedLeads,
} from "../../redux/auth/authSlice";

import logo from "../../images/newleey_text_logo.webp";

function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showVerifyPassword, setShowVerifyPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      verifyPassword: "",
      submit: null,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().max(255).required("First Name is required"),
      lastName: Yup.string().max(255).required("Last Name is required"),
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(
          /^(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
          "Password must contain at least one uppercase letter and one symbol"
        )
        .required("Password is required"),
      verifyPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Verify Password is required"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const data = {
          first: values.firstName,
          last: values.lastName,
          email: values.email,
          password: values.password,
        };
        const response = await axios.post(
          "https://services.newleey.com/api/user/register",
          data
        );

        console.log(response.data);

        if (response.data.user.token) {
          // Store the token in session storage
          sessionStorage.setItem("token", response.data.user.token);
          store.dispatch({ type: "CHECK_TOKEN_ON_LOAD" });
          navigate("/onboarding");
        }
      } catch (err) {
        if (err.response && err.response.status === 401) {
          // Handle the "Unauthorized" (401) error, indicating invalid credentials
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: "Invalid credentials" });
        } else if (err.response && err.response.status === 409) {
          // Handle the "Conflict" (409) error, indicating duplicate email
          helpers.setStatus({ success: false });
          helpers.setErrors({
            submit:
              "Email already exists. Please use a different email address.",
          });
        } else {
          // Handle other errors
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.message });
        }
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <Box
      sx={{
        flex: "1 1 auto",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        height: "98vh",
        backgroundColor: "#14243E",
        borderRadius: "5px",
      }}
    >
      <Box
        sx={{
          maxWidth: 550,
          px: 3,
          py: "100px",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={logo} alt="Newleey Logo" style={{ width: "60%" }} />
        </Box>
        <Card
          sx={{
            p: 3,
            mt: "60px",
          }}
        >
          <Stack spacing={1} sx={{ mb: 3 }}>
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              Register
            </Typography>
            <Typography
              color="text.secondary"
              variant="body2"
              sx={{ textAlign: "center" }}
            >
              Ready to start finding leads? Register now!
            </Typography>
          </Stack>
          <form noValidate onSubmit={formik.handleSubmit}>
            <Stack spacing={3} mb={2} direction="row">
              <TextField
                error={!!(formik.touched.firstName && formik.errors.firstName)}
                fullWidth
                helperText={formik.touched.firstName && formik.errors.firstName}
                label="First Name"
                name="firstName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.firstName}
                sx={{ mb: 2 }}
              />
              <TextField
                error={!!(formik.touched.lastName && formik.errors.lastName)}
                fullWidth
                helperText={formik.touched.lastName && formik.errors.lastName}
                label="Last Name"
                name="lastName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.lastName}
                sx={{ mb: 2 }}
              />
            </Stack>
            <TextField
              error={!!(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              label="Email Address"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
              sx={{ mb: 2 }}
            />
            <TextField
              error={!!(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="Password"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type={showPassword ? "text" : "password"}
              value={formik.values.password}
              sx={{ mb: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              error={
                !!(
                  formik.touched.verifyPassword && formik.errors.verifyPassword
                )
              }
              fullWidth
              helperText={
                formik.touched.verifyPassword && formik.errors.verifyPassword
              }
              label="Verify Password"
              name="verifyPassword"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type={showVerifyPassword ? "text" : "password"}
              value={formik.values.verifyPassword}
              sx={{ mb: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowVerifyPassword(!showVerifyPassword)}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showVerifyPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {/* Display password requirements */}
            <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
              Password must be at least 8 characters and include at least one
              uppercase letter and one symbol.
            </Typography>

            {formik.errors.submit && (
              <Typography color="error" sx={{ mt: 3 }} variant="body2">
                {formik.errors.submit}
              </Typography>
            )}

            <Button
              fullWidth
              size="large"
              sx={{
                backgroundColor: "#2D3DFF",
                mt: 3,
              }}
              type="submit"
              variant="contained"
            >
              Continue
            </Button>
          </form>
        </Card>
      </Box>
    </Box>
  );
}

export default Register;
