import React from "react";

import {
  Avatar,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
  SvgIcon,
} from "@mui/material";

import StarIcon from "@mui/icons-material/Star";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import {
  getFavoritedLeads,
  getPremiumLeads,
  fetchPremiumLeads,
  getPurchasedLeads,
  fetchPurchasedLeads,
} from "../../../redux/auth/authSlice";
import { useSelector } from "react-redux";
import { setComponent } from "../../../redux/dashboardSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

function OverviewCards() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPremiumLeads());
    dispatch(fetchPurchasedLeads());
  }, []);

  const favoriteLeads = useSelector(getFavoritedLeads);
  const premiumLeads = useSelector(getPremiumLeads);
  const purchasedLeads = useSelector(getPurchasedLeads);

  function handleClick(component) {
    dispatch(setComponent(component));
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Card variant="outlined">
          <CardContent>
            <Stack
              alignItems="flex-start"
              direction="row"
              justifyContent="space-between"
              spacing={3}
            >
              <Stack>
                <Typography color="text.secondary" variant="overline">
                  Purchased Leads
                </Typography>
                <Typography variant="h3">
                  {purchasedLeads.length ? purchasedLeads.length : 0}
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => handleClick("purchased leads")}
                  sx={{
                    mt: 2,
                    minWidth: "100px",
                    maxWidth: "100px",
                    backgroundColor: "#2D3DFF",
                    ":hover": { backgroundColor: "#14243E" },
                  }}
                >
                  View
                </Button>
              </Stack>
              <Avatar
                sx={{
                  backgroundColor: "#14243E",
                  height: 56,
                  width: 56,
                }}
              >
                <SvgIcon>
                  <ShoppingCartIcon />
                </SvgIcon>
              </Avatar>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card variant="outlined">
          <CardContent>
            <Stack
              alignItems="flex-start"
              direction="row"
              justifyContent="space-between"
              spacing={3}
            >
              <Stack>
                <Typography color="text.secondary" variant="overline">
                  Reamining Premium Leads
                </Typography>
                <Typography variant="h3">{premiumLeads}</Typography>
                <Button
                  variant="contained"
                  onClick={() => handleClick("account")}
                  sx={{
                    mt: 2,
                    minWidth: "100px",
                    maxWidth: "100px",
                    backgroundColor: "#2D3DFF",
                    ":hover": { backgroundColor: "#14243E" },
                  }}
                >
                  Purchase
                </Button>
              </Stack>
              <Avatar
                sx={{
                  backgroundColor: "#14243E",
                  height: 56,
                  width: 56,
                }}
              >
                <SvgIcon>
                  <WorkspacePremiumIcon />
                </SvgIcon>
              </Avatar>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card variant="outlined">
          <CardContent>
            <Stack
              alignItems="flex-start"
              direction="row"
              justifyContent="space-between"
              spacing={3}
            >
              <Stack>
                <Typography color="text.secondary" variant="overline">
                  Favorited Leads
                </Typography>
                <Typography variant="h3">
                  {favoriteLeads.length > 0 ? favoriteLeads.length : 0}
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => handleClick("favorited leads")}
                  sx={{
                    mt: 2,
                    minWidth: "100px",
                    maxWidth: "100px",
                    backgroundColor: "#2D3DFF",
                    ":hover": { backgroundColor: "#14243E" },
                  }}
                >
                  View
                </Button>
              </Stack>
              <Avatar
                sx={{
                  backgroundColor: "#14243E",
                  height: 56,
                  width: 56,
                }}
              >
                <SvgIcon>
                  <StarIcon />
                </SvgIcon>
              </Avatar>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default OverviewCards;
