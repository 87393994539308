import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Toolbar,
  Grid,
} from "@mui/material";
import { useSelector } from "react-redux";
import { getEmail } from "../../../redux/auth/authSlice";

const PaymentForm = () => {
  const firstName = useSelector((state) => state.auth.first_name);
  const lastName = useSelector((state) => state.auth.family_name);
  const picture = useSelector((state) => state.auth.picture);
  const email = useSelector(getEmail);
  const premium_leads = useSelector((state) => state.auth.premium_leads);
  const purchased_leads = useSelector((state) => state.auth.purchased_leads);

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, bgcolor: "background.default", p: 20 }}
    >
      <Toolbar />
      <Typography variant="h4" component="h1" gutterBottom>
        Account Details
      </Typography>
      <Card
        variant="outlined"
        sx={{
          p: 5,
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 3,
            }}
          >
            <img src={picture} style={{ borderRadius: "70px" }} />
            <Typography variant="h5" component="h2" gutterBottom>
              {`${firstName} ${lastName}`}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="overline" gutterBottom>
              Email:
            </Typography>
            <Typography variant="h6">{email}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="overline" gutterBottom>
              Premium Leads Remaining:
            </Typography>
            <Typography variant="h6">{premium_leads}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="overline" gutterBottom>
              Purchased Leads:
            </Typography>
            <Typography variant="h6">{purchased_leads.length}</Typography>
          </Grid>
        </Grid>
      </Card>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" component="h1" gutterBottom mt={3}>
            Need Support?
          </Typography>
          <Card variant="outlined" sx={{ p: 5 }}>
            <Typography variant="h6" gutterBottom>
              If you need support, please send an email to{" "}
              {<a href="mailto:hello@newleey.com">hello@newleey.com</a>}
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PaymentForm;
