import React, { useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  TextField,
  Button,
  Typography,
  Stack,
  Card,
  Link,
  CircularProgress,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { initialUserData } from "../../redux/auth/authSlice";

import logo from "../../images/newleey_text_logo.webp";

function VanillaLogin(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      submit: null,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
      password: Yup.string().max(255).required("Password is required"),
    }),
    onSubmit: async (values, helpers) => {
      setLoading(true);
      try {
        const data = {
          email: values.email,
          password: values.password,
        };
        const response = await axios.post(
          "https://services.newleey.com/api/user/vanilla-login",
          data
        );
        console.log(response.data);
        sessionStorage.setItem("token", response.data.token);
        if (response.data.token) {
          await dispatch(initialUserData())
            .unwrap()
            .then((response) => {
              if (response.onboarded === false) {
                navigate("/onboarding");
              } else {
                setTimeout(() => {
                  navigate("/");
                }, 3000);
              }
            });
        }
      } catch (err) {
        if (err.response && err.response.status === 401) {
          // Handle the "Unauthorized" (401) error, indicating invalid credentials
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: "Invalid credentials" });
        } else {
          // Handle other errors
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.message });
        }
        setLoading(false);
        helpers.setSubmitting(false);
      }
    },
  });
  return (
    <>
      <Card
        sx={{
          p: 3,
          mt: "60px",
          width: "80%",
        }}
      >
        <Stack spacing={1} sx={{ mb: 3 }}>
          <Typography
            color="text.secondary"
            variant="body2"
            sx={{ textAlign: "center" }}
            onClick={() => navigate("/register")}
          >
            Don't have an account?
            <Link
              component="span"
              onClick={() => navigate("/register")}
              sx={{
                cursor: "pointer",
                color: "blue",
                textDecoration: "underline",
              }}
            >
              Register here
            </Link>
          </Typography>
          <Typography
            color="text.secondary"
            variant="body2"
            sx={{ textAlign: "center" }}
            onClick={() => navigate("/forgot-password")}
          >
            Forgot your password?
            <Link
              component="span"
              onClick={() => navigate("/forgot-password")}
              sx={{
                cursor: "pointer",
                color: "blue",
                textDecoration: "underline",
              }}
            >
              Reset your password
            </Link>
          </Typography>
        </Stack>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Stack spacing={3}>
            <TextField
              error={!!(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              label="Email Address"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
            />
            <TextField
              error={!!(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="Password"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type={showPassword ? "text" : "password"}
              value={formik.values.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          {formik.errors.submit && (
            <Typography color="error" sx={{ mt: 3 }} variant="body2">
              {formik.errors.submit}
            </Typography>
          )}
          <Box
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                fullWidth
                size="large"
                sx={{
                  backgroundColor: "#2D3DFF",
                  mt: 3,
                }}
                type="submit"
                variant="contained"
              >
                Continue
              </Button>
            )}
          </Box>
        </form>
      </Card>
    </>
  );
}

export default VanillaLogin;
