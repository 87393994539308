import Grid from '@mui/material/Unstable_Grid2';
import { Box, ThemeProvider, createTheme } from '@mui/material';
import Menu from "./components/mapview/menu/Menu";
import Map from "./components/mapview/map/Map";
import Sidebar from './components/mapview/sidebar/Sidebar';

import { useRef } from 'react';

const theme = createTheme({
  palette: {
    primary: {
      main: '#14243E', // Set your primary color here
      contrastText: '#FFFFFF', // Set your text color here
    },
    secondary: {
      main: '#FFA500', // Set your secondary color here
    },
    background: {
      default: '#F7F7F7', // Set your default background color here
    },
    text: {
      primary: "#000000"
    }
  },
    shape: {
    borderRadius: 12,
  },
});


function App() {
  const mapRef = useRef(null)

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ backgroundColor: theme.palette.background.default }}>
        <Menu />
        <Grid container spacing={2}>
          <Map
            ref={mapRef}
            sx={{ height: '500px' }} // Set the height of the map container
          />
          <Sidebar
            ref={mapRef}
            sx={{ height: '300px' }} // Set the height of the sidebar container
          />
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export default App;
