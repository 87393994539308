import React from "react";
import {
  Box,
  Card,
  CardHeader,
  CardActions,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Divider,
  Button,
  SvgIcon,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useSelector } from "react-redux";
import { selectAllLeads } from "../../../redux/leadSlice";
import { useNavigate } from "react-router-dom";

function LatestLeads(props) {
  const leads = useSelector(selectAllLeads);
  const navigate = useNavigate();

  function handleTemp(temp) {
    if (temp === "Cold") {
      return "#cbe7f7";
    } else if (temp === "Hot") {
      return "#f2786f";
    }
    return "#c9c9c9";
  }

  return (
    <Card sx={{ marginTop: 3 }} variant="outlined">
      <CardHeader title="Latest Leads" />
      <Box sx={{ minHeight: "49vh", maxHeight: "49vh", overflowY: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Lead</TableCell>
              <TableCell>Type</TableCell>
              <TableCell sortDirection="desc">Date</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leads
              .filter((lead) => {
                const today = new Date();
                const leadDate = new Date(lead.date);
                // console.log(leadDate.getDate(), today.getDate());
                return (
                  leadDate.getFullYear() === today.getFullYear() &&
                  leadDate.getMonth() === today.getMonth() &&
                  leadDate.getDate() === today.getDate()
                );
              })
              .map((lead) => {
                return (
                  <TableRow hover key={lead.id}>
                    <TableCell>{lead.state}</TableCell>
                    <TableCell>{lead.leadType}</TableCell>
                    <TableCell>
                      {new Date(lead.date).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })}
                    </TableCell>
                    <TableCell>
                      <Card
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          backgroundColor: handleTemp(lead.leadTemp),
                          color: "black",
                          borderRadius: 10,
                          boxShadow: 0,
                        }}
                      >
                        <Typography variant="overline">
                          {lead.leadTemp}
                        </Typography>
                      </Card>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Box>
      <Divider />
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button
          color="inherit"
          endIcon={
            <SvgIcon fontSize="small">
              <ArrowRightIcon />
            </SvgIcon>
          }
          size="small"
          variant="text"
          onClick={() => {
            navigate("/");
          }}
        >
          View Map
        </Button>
      </CardActions>
    </Card>
  );
}

export default LatestLeads;
