import { configureStore } from '@reduxjs/toolkit';
import leadReducer from './leadSlice';
import dashboardReducer from './dashboardSlice';
import authReducer from './auth/authSlice';
import mapReducer from './mapSlice'
import { checkTokenOnLoad } from './auth/authMiddleware';

const store = configureStore({
  reducer: {
    leads: leadReducer,
    dashboard: dashboardReducer,
    auth: authReducer,
    map: mapReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(checkTokenOnLoad),
});

export default store;
