import React from 'react';
import { Box, Card, Grid } from "@mui/material";
import logo from '../../../images/newleey_text_logo.webp'
import { useTheme } from '@mui/material/styles';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

import { useSelector } from 'react-redux';
import { getFirstName } from '../../../redux/auth/authSlice';


const MenuStyle = {
  padding: '15px',
};

const MenuContainerStyle = {
  marginBottom: '15px',
};

const imgStyle = {
  width: '25%',
};

function Menu() {
  const theme = useTheme();

  const firstName = useSelector(getFirstName);

  return (
    <Grid container spacing={2} sx={MenuContainerStyle}>
      <Grid item xs={12}>
        <Card sx={{ ...MenuStyle, backgroundColor: "#2D3DFF" }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6} sx={{gap: '10px'}}>
              <img src={logo} alt="logo" style={imgStyle} />
              <Typography sx={{color: 'white'}}>
                Hi {firstName}! Welcome to Newleey
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Link to="/dashboard" style={{ textDecoration: 'none' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px'}}>
                  <AccountBoxIcon sx={{ fontSize: 45, color: 'white' }} />
                  <Typography sx={{color: 'white'}}>
                      Dashboard
                  </Typography>
                </Box>
              </Link>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Menu;
