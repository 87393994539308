import React, { forwardRef, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";
import PropertyCard from "./PropertyCard";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  selectAllLeads,
  setFilteredLeads,
  selectAllLeadStates,
  selectAllLeadDealTypes,
  selectAllLeadTypes,
} from "../../../redux/leadSlice";

const MapContainerStyles = {
  padding: "10px",
  paddingTop: 1,
  height: "60vh",
  boxShadow: 0,
  overflowY: "scroll",
  backgroundColor: "#F7F7F7",
  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  scrollbarWidth: "auto",
  scrollbarColor: "#14243E #ffffff",
  /* Chrome, Edge, and Safari */
  "&::-webkit-scrollbar": {
    width: "16px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#ffffff",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#14243E",
    borderRadius: "12px",
    border: "3px solid #ffffff",
  },
};

const Sidebar = forwardRef((props, ref) => {
  const leads = useSelector(selectAllLeads);
  const leadStates = useSelector(selectAllLeadStates);
  const dealTypes = useSelector(selectAllLeadDealTypes);
  const leadTypes = useSelector(selectAllLeadTypes);
  const dispatch = useDispatch();
  const [selectedDealType, setSelectedDealType] = useState("All");
  const [selectedState, setSelectedState] = useState("All");
  const [selectedLeadType, setSelectedLeadType] = useState("All");

  const todayLeads = leads.filter((lead) => {
    const leadDate = new Date(lead.date);
    const today = new Date();
    return leadDate.toDateString() === today.toDateString();
  });

  const handleFilterChange = (field, value) => {
    // Dispatch the setFilteredLeads action with both dealType and state filters
    console.log(field, value);

    dispatch(
      setFilteredLeads({
        filters: {
          ...{ dealType: selectedDealType, state: selectedState },
          [field]: value,
        },
      })
    );

    if (field === "dealType") {
      setSelectedDealType(value);
    } else if (field === "state") {
      setSelectedState(value);
    } else if (field === "leadType") {
      setSelectedLeadType(value);
    }
  };

  return (
    <Grid ref={ref} xs={4} sx={{ height: "86vh" }}>
      <Card sx={{ marginBottom: 2, padding: 2 }} variant="outlined">
        <Grid container spacing={2}>
          <Grid xs={6} sx={{ textAlign: "center" }}>
            <Typography variant="h6">Total Leads: {leads.length}</Typography>
          </Grid>
          <Grid xs={6} sx={{ textAlign: "center" }}>
            <Typography variant="h6">
              Today's Leads: {todayLeads.length}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Card
        sx={{ marginBottom: 2, padding: 2, paddingTop: 1 }}
        variant="outlined"
      >
        <Grid container spacing={2}>
          <Grid xs={4}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="select-label-1">Deal Type</InputLabel>
              <Select
                labelId="select-label-1"
                id="select-1"
                value={selectedDealType}
                onChange={(event) =>
                  handleFilterChange("dealType", event.target.value)
                }
              >
                <MenuItem value="All">All</MenuItem>
                {dealTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={4}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="select-label-2">State</InputLabel>
              <Select
                labelId="select-label-2"
                id="select-2"
                value={selectedState}
                onChange={(event) =>
                  handleFilterChange("state", event.target.value)
                }
              >
                <MenuItem value="All">All</MenuItem>
                {leadStates.map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={4}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="select-label-3">Lead Type</InputLabel>
              <Select
                labelId="select-label-3"
                id="select-3"
                value={selectedLeadType}
                onChange={(event) =>
                  handleFilterChange("leadType", event.target.value)
                }
              >
                <MenuItem value={"All"}>All</MenuItem>
                {leadTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Card>
      <Typography variant="h6" sx={{ textAlign: "center", marginBottom: 2 }}>
        Leads Available For Purchase
      </Typography>
      <Box sx={MapContainerStyles}>
        <PropertyCard ref={ref} />
      </Box>
    </Grid>
  );
});

export default Sidebar;
