import React from "react";
import { Box, Typography } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

import { useDispatch, useSelector } from "react-redux";
import { selectCard, selectSelectedCardId } from "../../../redux/leadSlice";
import { setZoom } from "../../../redux/mapSlice";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 150,
    minWidth: 150,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
  "& .MuiTooltip-arrow": {
    color: "#2D3DFF", // Change this to the color you want for the arrow
  },
}));

function MapMarker(props) {
  const dispatch = useDispatch();

  // In the MapMarker component
  const handleMarkerClick = () => {
    props.map_ref.setCenter({ lat: props.lat, lng: props.lng });
    props.map_ref.setZoom(12);
    dispatch(selectCard({ cardId: props.lead._id }));
  };

  const markerStyle = {
    position: "absolute",
    fontSize: "24px",
    color: "red",
    transform: "translate(-50%, -100%)",
  };

  return (
    <HtmlTooltip
      title={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0,
          }}
        >
          <Typography variant="overline">City: {props.city}</Typography>
          <Typography variant="overline">Lead: {props.leadType}</Typography>
          <Typography variant="overline">
            Deal Type: {props.dealType}
          </Typography>
          <Typography variant="overline">Zip: {props.zip}</Typography>
        </Box>
      }
      arrow
      placement="top"
      onClick={handleMarkerClick}
    >
      <div style={markerStyle}>{props.emoji}</div>
    </HtmlTooltip>
  );
}

export default MapMarker;
