import { createSlice } from '@reduxjs/toolkit';

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        component: 'overview', // default component to render
    },
    reducers: {
        setComponent: (state, action) => {
            state.component = action.payload;
        },
    },
});

export const { setComponent } = dashboardSlice.actions;

export default dashboardSlice.reducer;
