import React, { useRef, useState, forwardRef, useEffect } from "react";
import { Card } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import GoogleMap from "google-maps-react-markers";
import MapMarker from "./MapMarker";
import useSupercluster from "use-supercluster";

import { useSelector } from "react-redux";
import { selectAllLeads } from "../../../redux/leadSlice";
import {
  getCenter,
  getZoom,
  getBounds,
  setBounds,
  setZoom,
} from "../../../redux/mapSlice";
import { useDispatch } from "react-redux";

const googleMapApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const MapContainerStyles = {
  boxShadow: 3,
  height: "100%",
};

const Marker = ({ children }) => children;

const Map = forwardRef((props, ref) => {
  const mapRef = ref;
  const dispatch = useDispatch();

  const leads = useSelector(selectAllLeads);
  const defaultCenter = useSelector(getCenter);
  const zoom = useSelector(getZoom);
  const bounds = useSelector(getBounds);
  const [mapReady, setMapReady] = useState(false);

  const mapOptions = {
    streetViewControl: false,
  };

  const onGoogleApiLoaded = ({ map }) => {
    mapRef.current = map;
    setMapReady(true);
  };

  const { clusters, supercluster } = useSupercluster({
    bounds: bounds,
    zoom: zoom,
    points: leads.map((lead) => ({
      type: "Feature",
      properties: {
        cluster: false,
        leadId: lead._id,
        city: lead.city,
        leadType: lead.leadType,
        dealType: lead.dealType,
        zip: lead.zip,
      },
      geometry: {
        type: "Point",
        coordinates: [
          parseFloat(lead.coordinates.longitude),
          parseFloat(lead.coordinates.latitude),
        ],
      },
    })),
  });

  const handleTempEmoji = (temp) => {
    if (temp.toLowerCase() === "cold") {
      return "🧊";
    } else if (temp.toLowerCase() === "hot") {
      return "🔥";
    } else if (temp.toLowerCase() === "real estate") {
      return "🏡";
    }
    return "📆";
  };

  useEffect(() => {
    if (mapReady) {
      mapRef.current.setZoom(4); // Set the zoom level to 4 when the map is ready
    }
  }, [mapReady]);

  return (
    <Grid xs={8} sx={{ height: "87vh" }}>
      <Card sx={MapContainerStyles}>
        <GoogleMap
          apiKey={googleMapApiKey}
          defaultCenter={defaultCenter}
          options={mapOptions}
          defaultZoom={zoom}
          onGoogleApiLoaded={onGoogleApiLoaded}
          onChange={({ zoom, bounds }) => {
            const ne = bounds.getNorthEast();
            const sw = bounds.getSouthWest();
            dispatch(setZoom({ zoom }));
            dispatch(
              setBounds({ bounds: [sw.lng(), sw.lat(), ne.lng(), ne.lat()] })
            );
          }}
        >
          {clusters.map((cluster) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const {
              cluster: isCluster,
              point_count: pointCount,
              city,
              leadType,
              dealType,
              zip,
            } = cluster.properties;

            if (isCluster) {
              return (
                <Marker
                  key={`cluster-${cluster.id}`}
                  lat={latitude}
                  lng={longitude}
                >
                  <Box
                    style={{
                      backgroundColor: "#2D3DFF",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "white",
                      width: "30px",
                      height: "30px",
                    }}
                    onClick={() => {
                      const expansionZoom = Math.min(
                        supercluster.getClusterExpansionZoom(cluster.id),
                        20
                      );
                      mapRef.current.setZoom(expansionZoom);
                      mapRef.current.panTo({ lat: latitude, lng: longitude });
                    }}
                  >
                    {pointCount}
                  </Box>
                </Marker>
              );
            }

            return (
              <MapMarker
                map_ref={mapRef.current}
                key={cluster.properties.leadId}
                lead={leads.find(
                  (lead) => lead._id === cluster.properties.leadId
                )}
                lat={latitude}
                lng={longitude}
                city={cluster.properties.city}
                leadType={cluster.properties.leadType}
                dealType={cluster.properties.dealType}
                zip={cluster.properties.zip}
                emoji={handleTempEmoji(
                  leads.find((lead) => lead._id === cluster.properties.leadId)
                    .leadTemp
                )}
              />
            );
          })}
        </GoogleMap>
      </Card>
    </Grid>
  );
});

export default Map;
