import { Navigate } from "react-router-dom";
import { initialUserData, setAuthenticated, setLoading } from "./authSlice";
import { fetchLeads } from "../leadSlice";

// Middleware to check if the token is valid on load. Sets the isAuthenticated state accordingly.
export const checkTokenOnLoad = (store) => (next) => async (action) => {
  if (action.type === "CHECK_TOKEN_ON_LOAD") {
    console.log("Checking token on load");
    store.dispatch(setLoading(true));

    // TODO: add an API call to check token validity. This will do for now.
    const storedToken = sessionStorage.getItem("token");

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await delay(5000);

    if (storedToken) {
      await store.dispatch(initialUserData());
      await store.dispatch(fetchLeads());
    } else {
      console.log("storedToken invalid");
      await store.dispatch(setAuthenticated(false));
      <Navigate to="/login" />;
    }
    store.dispatch(setLoading(false));
  }

  // Call the next middleware or the reducer
  return next(action);
};
