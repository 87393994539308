import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Grow,
  Box,
  Toolbar,
  Button,
  OutlinedInput,
  InputAdornment,
  SvgIcon,
  CardHeader,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  SnackbarContent,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector, useDispatch } from "react-redux";
import {
  getFavoritedLeads,
  getPurchasedLeads,
  fetchPremiumLeads,
  getUserId,
  getPremiumLeads,
  appendPurchasedLeads,
} from "../../../redux/auth/authSlice";
import { selectAllLeads } from "../../../redux/leadSlice";

function PurchasedLeads(props) {
  const dispatch = useDispatch();

  const [grow, setGrow] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectedConfirmationLeadId, setSelectedConfirmationLeadId] =
    useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const favoriteLeads = useSelector(getFavoritedLeads);
  const leads = useSelector(selectAllLeads);
  const purchasedLeads = useSelector(getPurchasedLeads);
  const premiumLeads = useSelector(getPremiumLeads);
  const userId = useSelector(getUserId);

  const [favoritedLeads, setFavoritedLeads] = useState([]);

  // Function to handle buy confirmation
  const handleBuyConfirmation = (leadId) => {
    // Check if the lead has already been purchased
    if (purchasedLeads && purchasedLeads.includes(leadId)) {
      // Display a snackbar informing the user that the lead has already been purchased
      setSnackbarMessage(
        "You have already purchased this lead. You can view it in your dashboard."
      );
      setSnackbarOpen(true);
    } else {
      // Continue with the confirmation dialog for purchasing the lead
      dispatch(fetchPremiumLeads());
      setSelectedConfirmationLeadId(leadId);
      setShowConfirmation(true);
    }
  };

  // Function to handle confirmation dialog close
  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  // Function to handle Snackbar close
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Function to handle Snackbar close
  const handleConfirmationConfirm = (leadId) => {
    axios
      .post("https://services.newleey.com/api/leads/purchase", {
        userId: userId,
        leadId: leadId,
      })
      .then((response) => {
        // Check if the response status is 200
        if (response.status === 200) {
          // Show Snackbar on success

          setSnackbarMessage(
            "Lead purchased successfully! You can view this lead in your dashboard."
          );
          setSnackbarOpen(true);
          dispatch(appendPurchasedLeads(leadId));
        } else {
          console.error("Unexpected status code:", response.status);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // Server responded with 400 - user doesn't have enough premium leads
          setSnackbarMessage(
            "You don't have enough premium leads. Purchase more in your dashboard."
          );
          setSnackbarOpen(true);
        } else if (error.response && error.response.status === 403) {
          setSnackbarMessage(
            "Lead has been purchased more than 3 times and is no longer active"
          );
          setSnackbarOpen(true);
        } else {
          console.error("Error purchasing lead:", error);
        }
      })
      .finally(() => {
        // Close the confirmation dialog
        handleConfirmationClose();
      });
  };

  useEffect(() => {
    // Filter leads based on favorited leads
    const filteredLeads = leads.filter((lead) =>
      favoriteLeads.includes(lead._id.toString())
    );
    setFavoritedLeads(filteredLeads);
  }, [favoriteLeads, leads]);

  function handleTemp(temp) {
    // Determine color based on temperature
    if (temp === "Cold") {
      return "#cbe7f7";
    } else if (temp === "Hot") {
      return "#f2786f";
    }
    return "#c9c9c9";
  }

  useEffect(() => {
    // Enable grow effect on mount
    setGrow(true);
  }, []);

  return (
    <Grow in={grow}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: 2,
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <Toolbar />
        <CardHeader title="Favorited Leads" />
        <Card sx={{ p: 2 }} variant="outlined">
          <OutlinedInput
            fullWidth
            placeholder="Search Favorited Leads"
            startAdornment={
              <InputAdornment position="start">
                <SvgIcon color="action" fontSize="small">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            }
            sx={{ maxWidth: 500 }}
            defaultValue=""
            onChange={(event) => {
              if (event.target.value === "") {
                // Reset to all favorited leads
                const filteredLeads = leads.filter((lead) =>
                  favoriteLeads.includes(lead._id.toString())
                );
                setFavoritedLeads(filteredLeads);
                return;
              }

              const searchValue = event.target.value;
              // Filter favorited leads based on search input
              const filteredLeads = leads.filter(
                (lead) =>
                  favoriteLeads.includes(lead._id.toString()) &&
                  lead.city.toLowerCase().includes(searchValue.toLowerCase())
              );
              setFavoritedLeads(filteredLeads);
            }}
          />
        </Card>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Lead</TableCell>
              <TableCell>Type</TableCell>
              <TableCell sortDirection="desc">Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Purchase</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {favoritedLeads.map((lead) => (
              <React.Fragment key={lead._id}>
                <TableRow>
                  <TableCell>{lead.city}</TableCell>
                  <TableCell>{lead.leadType}</TableCell>
                  <TableCell>
                    {new Date(lead.date).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    <Card
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: handleTemp(lead.leadTemp),
                        color: "black",
                        borderRadius: 10,
                        boxShadow: 0,
                      }}
                    >
                      <Typography variant="overline">
                        {lead.leadTemp}
                      </Typography>
                    </Card>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {/* Purchase button */}
                    {purchasedLeads && purchasedLeads.includes(lead._id) ? (
                      <Button
                        sx={{
                          borderRadius: 10,
                          boxShadow: 0,
                          background: "#c9c9c9", // Gray background for purchased leads
                          color: "white",
                        }}
                        variant="contained"
                        disabled
                      >
                        Purchased
                      </Button>
                    ) : lead.active ? (
                      <Button
                        sx={{
                          borderRadius: 10,
                          boxShadow: 0,
                          background: "#2C3DFF",
                        }}
                        variant="contained"
                        onClick={() => handleBuyConfirmation(lead._id)}
                      >
                        Purchase
                      </Button>
                    ) : (
                      <Button
                        sx={{
                          borderRadius: 10,
                          boxShadow: 0,
                          background: "#c9c9c9",
                        }}
                        variant="contained"
                        disabled
                      >
                        Inactive
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
        <Dialog open={showConfirmation} onClose={handleConfirmationClose}>
          <DialogTitle>
            <Typography variant="h5">Do you want to buy this lead?</Typography>
            <Typography variant="overline">
              You have {premiumLeads} premium leads remaining
            </Typography>
          </DialogTitle>
          <DialogContent>
            {selectedConfirmationLeadId &&
              leads.map((lead) => {
                if (lead._id === selectedConfirmationLeadId) {
                  return (
                    <React.Fragment key={lead._id}>
                      <Typography variant="body1">
                        Lead Type: {lead.leadType}
                      </Typography>
                      <Typography variant="body1">
                        Location: {lead.city}, {lead.state}
                      </Typography>
                      <Typography variant="body1">Zip: {lead.zip}</Typography>
                      <Typography variant="body1">
                        Deal Type: {lead.dealType}
                      </Typography>
                    </React.Fragment>
                  );
                }
                return null;
              })}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmationClose}>Cancel</Button>
            <Button
              onClick={() =>
                handleConfirmationConfirm(selectedConfirmationLeadId)
              }
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={handleSnackbarClose}
        >
          <SnackbarContent message={snackbarMessage} />
        </Snackbar>
      </Box>
    </Grow>
  );
}

export default PurchasedLeads;
