import React from "react";
import {
  Drawer,
  Toolbar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"; // Import the necessary components from Material-UI
import BarChartIcon from "@mui/icons-material/BarChart"; // Import icons from Material-UI
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import PersonIcon from "@mui/icons-material/Person";
import StarIcon from "@mui/icons-material/Star";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout"; // Import Logout icon from Material-UI
import logo from "../../../images/newleey_text_logo.webp";
import { Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { setComponent } from "../../../redux/dashboardSlice";
import { logout } from "../../../redux/auth/authSlice";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom

const drawerWidth = 250; // Replace with the actual width you want for your drawer

function DashboardSideBar(props) {
  const dispatch = useDispatch();
  const component = useSelector((state) => state.dashboard.component);
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleComponentChange = (newComponent) => {
    dispatch(setComponent(newComponent));
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login"); // Perform programmatic navigation using navigate function
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between", // Place content at the top and bottom of the drawer
        },
      }}
      PaperProps={{
        sx: {
          backgroundColor: "#14243E",
          color: "white",
          height: "100%", // Make the drawer take up the full height
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar>
        <Link to={"/"}>
          <Box
            component="img"
            src={logo}
            sx={{
              height: 35,
              display: "flex",
              justifyContent: "center",
            }}
          />
        </Link>
      </Toolbar>
      <Divider sx={{ backgroundColor: "rgb(47, 55, 70)" }} />
      <List sx={{ pl: "15px", pr: "15px" }}>
        <ListItem key={"Overview"} disablePadding>
          <ListItemButton
            selected={component === "overview"}
            onClick={() => handleComponentChange("overview")}
            sx={{
              pt: 1,
              pb: 1,
              borderRadius: 3,
              "&.Mui-selected": {
                backgroundColor: "#2431c9",
                pt: 1,
                pb: 1,
              },
              ":hover": {
                backgroundColor: "#2D3DFF",
              },
            }}
          >
            <ListItemIcon>
              <BarChartIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText primary={"Overview"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"Purchased Leads"} disablePadding>
          <ListItemButton
            onClick={() => handleComponentChange("purchased leads")}
            selected={component === "purchased leads"}
            sx={{
              pt: 1,
              pb: 1,
              mt: 1,
              borderRadius: 3,
              "&.Mui-selected": {
                backgroundColor: "#2431c9",
              },
              ":hover": {
                backgroundColor: "#2D3DFF",
              },
            }}
          >
            <ListItemIcon>
              <HomeWorkIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText primary={"Purchased Leads"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"Favorited Leads"} disablePadding>
          <ListItemButton
            onClick={() => handleComponentChange("favorited leads")}
            selected={component === "favorited leads"}
            sx={{
              pt: 1,
              pb: 1,
              mt: 1,
              borderRadius: 3,
              "&.Mui-selected": {
                backgroundColor: "#2431c9",
              },
              ":hover": {
                backgroundColor: "#2D3DFF",
              },
            }}
          >
            <ListItemIcon>
              <StarIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText primary={"Favorited Leads"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"Purchase"} disablePadding>
          <ListItemButton
            selected={component === "account"}
            onClick={() => handleComponentChange("account")}
            sx={{
              pt: 1,
              pb: 1,
              mt: 1,
              borderRadius: 3,
              "&.Mui-selected": {
                backgroundColor: "#2431c9",
              },
              ":hover": {
                backgroundColor: "#2D3DFF",
              },
            }}
          >
            <ListItemIcon>
              <PersonIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText primary={"Account"} />
          </ListItemButton>
        </ListItem>
        {props.isAdmin && (
          <ListItem key={"Administrative"} disablePadding>
            <ListItemButton
              selected={component === "administrative"}
              onClick={() => handleComponentChange("administrative")}
              sx={{
                pt: 1,
                pb: 1,
                mt: 1,
                borderRadius: 3,
                "&.Mui-selected": {
                  backgroundColor: "#2431c9",
                },
                ":hover": {
                  backgroundColor: "#2D3DFF",
                },
              }}
            >
              <ListItemIcon>
                <SettingsIcon sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText primary={"Administrative"} />
            </ListItemButton>
          </ListItem>
        )}
      </List>
      <ListItem
        key={"Logout"}
        sx={{
          mt: "auto", // Push the logout button to the bottom
        }}
      >
        <ListItemButton
          onClick={handleLogout}
          sx={{
            pt: 1,
            pb: 1,
            borderRadius: 3,
            ":hover": {
              backgroundColor: "#2D3DFF",
            },
          }}
        >
          <ListItemIcon>
            <LogoutIcon sx={{ color: "white" }} />
          </ListItemIcon>
          <ListItemText primary={"Logout"} />
        </ListItemButton>
      </ListItem>
    </Drawer>
  );
}

export default DashboardSideBar;
