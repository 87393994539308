import React, { useEffect, useState } from "react";

import { Box, Grid, Toolbar } from "@mui/material";

import { Grow } from "@mui/material";

import OverviewCards from "./OverviewCards";
import LatestLeads from "./PurchasedLeadsCard";
import PurchasedLeads from "./PurchasedLeads";
import FavoritedLeads from "./FavoritedLeadsCard";

function Overview() {
  const [grow, setGrow] = useState(false);

  useEffect(() => {
    setGrow(true);
  }, []);

  return (
    <Grow in={grow}>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 2 }}
      >
        <Toolbar />
        <OverviewCards />
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <LatestLeads />
          </Grid>
          <Grid item xs={4}>
            <FavoritedLeads />
          </Grid>
        </Grid>
      </Box>
    </Grow>
  );
}

export default Overview;
