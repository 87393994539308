import React, { useState } from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MapIcon from "@mui/icons-material/Map";
import { useSelector } from "react-redux";
import DashboardSideBar from "./components/DashboardSideBar";
import Overview from "./components/Overview";
import PurchasedLeads from "./components/PurchasedLeads";
import FavoritedLeads from "./components/FavoritedLeads";
import PaymentForm from "./components/AccountView";
import Administrative from "./components/Administrative";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { useEffect } from "react";
import axios from "axios";

const stripePromise = loadStripe(
  "pk_test_51OHtfLKXnToZUnvoZMlmPaZKKl6ZymDqtjWfSzMRIngv1jXYvYTkocezkEORfAjBCS3BqIRPQhOu34LuOhnc2tlp00ZbUnWTln"
);

const drawerWidth = 240;

const Dashboard = () => {
  console.log("rendering dashboard");
  const [isAdmin, setIsAdmin] = useState(false);
  const component = useSelector((state) => state.dashboard.component);
  const user_id = useSelector((state) => state.auth.user_id);

  useEffect(() => {
    async function getUserAdmin() {
      console.log("getting user admin");
      const token = sessionStorage.getItem("token");
      const config = {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      };

      try {
        const response = await axios.post(
          "https://services.newleey.com/api/user/check-admin",
          { user_id: user_id }, // Pass an empty object as the second argument
          config
        );
        if (response.status === 200) {
          console.log(response.data.isAdmin);
          setIsAdmin(response.data.isAdmin);
        }
      } catch (error) {
        console.error(error);
      }
    }

    getUserAdmin();
  }, []);

  let view;

  if (component === "overview") {
    view = <Overview />;
  }
  if (component === "purchased leads") {
    view = <PurchasedLeads />;
  }
  if (component === "favorited leads") {
    view = <FavoritedLeads />;
  }
  if (component === "account") {
    view = <PaymentForm />;
  }
  if (component === "administrative" && isAdmin) {
    view = <Administrative />;
  }

  return (
    <Elements stripe={stripePromise}>
      <Box sx={{ display: "flex" }}>
        <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
          }}
        >
          <Toolbar
            sx={{
              background: "#2D3DFF",
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <Link to={"/"} style={{ textDecoration: "none" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <MapIcon
                  sx={{
                    color: "white",
                    fontSize: 45,
                  }}
                />
                <Typography sx={{ color: "white" }}>Map</Typography>
              </Box>
            </Link>
          </Toolbar>
        </AppBar>
        <DashboardSideBar isAdmin={isAdmin} />
        {view}
      </Box>
    </Elements>
  );
};

export default Dashboard;
