import React from "react";
import { Box, CircularProgress, Card, Typography, Stack } from "@mui/material";
import logo from "./images/newleey_text_logo.webp";

const Loading = () => {
  return (
    <Box
      sx={{
        flex: "1 1 auto",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        height: "98vh",
        backgroundColor: "#2D3DFF",
      }}
    >
      <Box
        sx={{
          maxWidth: 550,
          px: 3,
          py: "100px",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={logo} alt="Newleey Logo" style={{ width: "60%" }} />
        </Box>
        <Card
          sx={{
            p: 3,
            mt: "60px",
          }}
        >
          <Stack spacing={1} sx={{ mb: 3, alignItems: "center", gap: 2 }}>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              We're getting some things ready for you. Give us a second!
            </Typography>
            <CircularProgress color="primary" />
          </Stack>
        </Card>
      </Box>
    </Box>
  );
};

export default Loading;
