import React, { useEffect, useState } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import App from "./App";
import Dashboard from "./components/dashboard/Dashboard";
import Login from "./components/user/Login";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import Loading from "./Loading";
import Onboarding from "./components/user/Onboarding";
import Register from "./components/user/Register";
import PasswordRecovery from "./components/user/PasswordRecovery";
import ResetPassword from "./components/user/ResetPassword";
import logo from "./images/newleey_text_logo_blue.webp";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

function Main() {
  const loading = useSelector((state) => state.auth.loading);
  const authenticated = useSelector((state) => state.auth.authenticated);
  const onboarded = useSelector((state) => state.auth.onboarded);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Detect if the user is on a mobile device
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the width as per your needs
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <BrowserRouter>
        <Routes>
          {isMobile && (
            <Route
              path="/"
              element={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <img src={logo} alt="Newleey Logo" style={{ width: "50%" }} />
                  <Typography
                    variant="h4"
                    sx={{ marginTop: 10, textAlign: "center" }}
                  >
                    Please use Newleey on a desktop for the best experience.
                  </Typography>
                </Box>
              }
            />
          )}
          {!isMobile && (
            <>
              <Route
                path="/"
                element={
                  loading ? (
                    <Loading />
                  ) : authenticated && onboarded ? (
                    <App />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/dashboard"
                element={
                  loading ? (
                    <Loading />
                  ) : authenticated ? (
                    <Dashboard />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route path="/login" element={<Login />} />
              <Route path="/onboarding" element={<Onboarding />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot-password" element={<PasswordRecovery />} />
              <Route path="/reset-password" element={<ResetPassword />} />
            </>
          )}
        </Routes>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export default Main;
