import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const removeFavoriteLead = createAsyncThunk(
  "auth/removeFavoriteLead",
  async ({ user_id, lead_id }, thunkAPI) => {
    try {
      const response = await axios.post(
        "https://services.newleey.com/api/user/favorite/remove",
        { user_id, lead_id }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const addFavoriteLead = createAsyncThunk(
  "auth/addFavoriteLead",
  async ({ user_id, lead_id }, thunkAPI) => {
    try {
      const response = await axios.post(
        "https://services.newleey.com/api/user/favorite/add",
        { user_id, lead_id }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchPremiumLeads = createAsyncThunk(
  "auth/fetchPremiumLeads",
  async (_, thunkAPI) => {
    const token = sessionStorage.getItem("token");
    const user_id = thunkAPI.getState().auth.user_id;

    console.log("fetchPremiumLeads");

    const config = {
      headers: {
        Authorization: token, // Fix header name
        "Content-Type": "application/json",
      },
    };

    const body = {
      user_id: user_id,
    };

    try {
      const response = await axios.post(
        "https://services.newleey.com/api/user/premium-leads",
        body,
        config
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const checkTokenValidity = createAsyncThunk(
  "auth/checkTokenValidity",
  async (token, thunkAPI) => {
    const storedToken = sessionStorage.getItem("token");
    try {
      const response = await axios.post(
        "https://services.newleey.com/api/user/token/check",
        { storedToken }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchPurchasedLeads = createAsyncThunk(
  "auth/fetchPurchasedLeads",
  async (_, thunkAPI) => {
    const token = sessionStorage.getItem("token");
    const user_id = thunkAPI.getState().auth.user_id;

    const config = {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    };

    const body = {
      user_id: user_id,
    };

    try {
      const response = await axios.post(
        "https://services.newleey.com/api/user/purchased_leads",
        body,
        config
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const initialUserData = createAsyncThunk(
  "auth/initialUserData",
  async (_, thunkAPI) => {
    const token = sessionStorage.getItem("token");
    const header = {
      Authorization: token,
    };

    try {
      const response = await axios.post(
        "https://services.newleey.com/api/user/authorize",
        null,
        { headers: header }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// export const initializeAuth = () => async (dispatch) => {
//   const storedToken = sessionStorage.getItem("token");
//   console.log("storedToken" + storedToken);

// };

const authSlice = createSlice({
  name: "auth",
  initialState: {
    authenticated: null,
    loading: false,
    error: null,
    user_id: null,
    favorited_leads: null,
    first_name: null,
    premium_leads: null,
    purchased_leads: null,
    email: null,
    picture: null,
    family_name: null,
    isNewUser: null,
    onboarded: null,
  },
  reducers: {
    logout: (state) => {
      state.authenticated = false;
      state.loading = false;
      state.error = null;
      state.user_id = null;
      state.favorited_leads = null;
      state.first_name = null;
      state.premium_leads = null;
      state.purchased_leads = null;
      state.email = null;
      state.picture = null;
      state.family_name = null;
      state.isNewUser = null;
      state.onboarded = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAuthenticated: (state, action) => {
      state.authenticated = action.payload;
    },
    setUserId: (state, action) => {
      state.user_id = action.payload.user_id;
    },
    setFavoritedLeads: (state, action) => {
      state.favorited_leads = action.payload.favorited_leads;
    },
    setPremiumLeads: (state, action) => {
      state.premium_leads = action.payload.premium_leads;
    },
    setEmail: (state, action) => {
      state.email = action.payload.email;
    },
    setPurchasedLeads: (state, action) => {
      state.purchased_leads = action.payload.purchased_leads;
    },
    setFirstName: (state, action) => {
      state.first_name = action.payload.first_name;
    },
    appendPurchasedLeads: (state, action) => {
      return {
        ...state,
        purchased_leads: [...state.purchased_leads, action.payload],
      };
    },
    setOnboarded: (state, action) => {
      state.onboarded = action.payload.onboarded;
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(removeFavoriteLead.pending, (state) => {
      //   state.loading = 'pending';
      // })
      .addCase(removeFavoriteLead.fulfilled, (state, action) => {
        state.loading = false;
        state.favorited_leads = action.payload.favorited_leads;
      })
      .addCase(removeFavoriteLead.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      // .addCase(addFavoriteLead.pending, (state) => {
      //   state.loading = 'pending';
      // })
      .addCase(addFavoriteLead.fulfilled, (state, action) => {
        state.loading = false;
        state.favorited_leads = action.payload.favorited_leads;
      })
      .addCase(addFavoriteLead.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      // .addCase(checkTokenValidity.pending, (state) => {
      //   state.loading = false;
      // })
      // .addCase(checkTokenValidity.fulfilled, (state, action) => {
      //   state.authenticated = action.payload.token_valid;
      //   state.loading = false;
      //   state.user_id = action.payload.user_id;
      //   state.favorited_leads = action.payload.favorited_leads;
      // })
      // .addCase(checkTokenValidity.rejected, (state) => {
      //   state.authenticated = false;
      //   state.loading = false;
      // })
      // .addCase(fetchPremiumLeads.pending, (state) => {
      //   state.loading = 'pending';
      // })
      .addCase(fetchPremiumLeads.fulfilled, (state, action) => {
        state.loading = false;
        state.premium_leads = action.payload.premium_leads;
      })
      .addCase(fetchPremiumLeads.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(fetchPurchasedLeads.fulfilled, (state, action) => {
        state.loading = false;
        state.purchased_leads = action.payload.purchased_leads;
      })
      .addCase(fetchPurchasedLeads.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(initialUserData.pending, (state) => {
        state.loading = true;
      })
      .addCase(initialUserData.fulfilled, (state, action) => {
        state.authenticated = true;
        state.first_name = action.payload.first_name;
        state.family_name = action.payload.last_name;
        state.user_id = action.payload.user_id;
        state.favorited_leads = action.payload.favorited_leads;
        state.premium_leads = action.payload.premium_leads;
        state.purchased_leads = action.payload.purchased_leads;
        state.email = action.payload.email;
        state.picture = action.payload.picture;
        state.loading = false;
        state.isNewUser = action.payload.isNewUser;
        state.onboarded = action.payload.onboarded;
      })
      .addCase(initialUserData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  appendPurchasedLeads,
  setAuthenticated,
  setUserId,
  setFavoritedLeads,
  setPremiumLeads,
  setEmail,
  setPurchasedLeads,
  setFirstName,
  setLoading,
  setOnboarded,
  logout,
} = authSlice.actions;
export const getFavoritedLeads = (state) => state.auth.favorited_leads;
export const getPremiumLeads = (state) => state.auth.premium_leads;
export const getPurchasedLeads = (state) => state.auth.purchased_leads;
export const getUserId = (state) => state.auth.user_id;
export const getLoading = (state) => state.auth.loading;
export const getEmail = (state) => state.auth.email;
export const getFirstName = (state) => state.auth.first_name;

export default authSlice.reducer;
