import React from "react";
import {
  Box,
  Card,
  CardHeader,
  CardActions,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Divider,
  Button,
  SvgIcon,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import { useSelector } from "react-redux";

import {
  getPurchasedLeads,
  fetchPurchasedLeads,
} from "../../../redux/auth/authSlice";
import { selectAllLeads } from "../../../redux/leadSlice";

import { useDispatch } from "react-redux";
import { setComponent } from "../../../redux/dashboardSlice";

function FavoritedLeadsCard(props) {
  const favoriteLeads = useSelector(getPurchasedLeads);
  const leads = useSelector(selectAllLeads);
  const dispatch = useDispatch();

  function handleClick() {
    dispatch(setComponent("purchased leads"));
  }

  function handleTemp(temp) {
    if (temp === "Cold") {
      return "#cbe7f7";
    } else if (temp === "Hot") {
      return "#f2786f";
    }

    return "#c9c9c9";
  }

  const reversedLeads = [...leads].reverse();

  return (
    <Card variant="outlined" sx={{ marginTop: 3 }}>
      <CardHeader title="Purchased Leads" />
      <Box
        sx={{
          minHeight: "49vh",
          maxHeight: "49vh",
          overflowY: "auto",
          scrollbarColor: "#14243E #ffffff",
          /* Chrome, Edge, and Safari */
          "&::-webkit-scrollbar": {
            width: "16px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#ffffff",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#14243E",
            borderRadius: "12px",
            border: "3px solid #ffffff",
          },
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>City</TableCell>
              <TableCell>Type</TableCell>
              <TableCell sortDirection="desc">Date</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reversedLeads.map((lead) => {
              if (!favoriteLeads.includes(lead._id.toString())) {
                // Skip leads that are not in the favoriteLeads array
                return null;
              }
              return (
                <React.Fragment key={lead._id}>
                  <TableRow>
                    <TableCell>{lead.city}</TableCell>
                    <TableCell>{lead.leadType}</TableCell>
                    <TableCell>
                      {new Date(lead.date).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })}
                    </TableCell>
                    <TableCell>
                      <Card
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          backgroundColor: handleTemp(lead.leadTemp),
                          color: "black",
                          borderRadius: 10,
                          boxShadow: 0,
                        }}
                      >
                        <Typography variant="overline">
                          {lead.leadTemp}
                        </Typography>
                      </Card>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      <Divider />
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button
          color="inherit"
          endIcon={
            <SvgIcon fontSize="small">
              <ArrowRightIcon />
            </SvgIcon>
          }
          size="small"
          variant="text"
          onClick={handleClick}
        >
          View all
        </Button>
      </CardActions>
    </Card>
  );
}

export default FavoritedLeadsCard;
