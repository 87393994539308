import React, { useState, useEffect } from "react";
import {
  Toolbar,
  Box,
  Card,
  CardHeader,
  TextField,
  MenuItem,
  Button,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  Grid,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { fetchLeads, selectAllLeads } from "../../../redux/leadSlice";
import { useDispatch } from "react-redux";
import axios from "axios";

const Administrative = () => {
  const [addressLine1, setAddressLine1] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [dealType, setDealType] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [leadTemperature, setLeadTemperature] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [addLeadMessage, setAddLeadMessage] = useState("");
  const [leadType, setLeadType] = useState("");
  const [leadContactName, setLeadContactName] = useState("");
  const [leadContactEmail, setLeadContactEmail] = useState("");
  const [leadContactPhone, setLeadContactPhone] = useState("");
  const [selectedLeadId, setSelectedLeadId] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUserRemoveLead, setSelectedUserRemoveLead] = useState("");
  const [selectedUserAddPremium, setSelectedUserAddPremium] = useState("");
  const [premiumLeadCredit, setPremiumLeadCredit] = useState(0);
  const [premiumUserLeads, setPremiumUserLeads] = useState([]);
  const [selectedLeadRemoveFromUser, setSelectedLeadRemoveFromUser] =
    useState();
  const [selectedLeadToRemove, setSelectedLeadToRemove] = useState(null);
  const leads = useSelector(selectAllLeads);
  const dispatch = useDispatch();

  const statesList = [
    { value: "AL", label: "Alabama" },
    { value: "AK", label: "Alaska" },
    { value: "AZ", label: "Arizona" },
    { value: "AR", label: "Arkansas" },
    { value: "CA", label: "California" },
    { value: "CO", label: "Colorado" },
    { value: "CT", label: "Connecticut" },
    { value: "DE", label: "Delaware" },
    { value: "FL", label: "Florida" },
    { value: "GA", label: "Georgia" },
    { value: "HI", label: "Hawaii" },
    { value: "ID", label: "Idaho" },
    { value: "IL", label: "Illinois" },
    { value: "IN", label: "Indiana" },
    { value: "IA", label: "Iowa" },
    { value: "KS", label: "Kansas" },
    { value: "KY", label: "Kentucky" },
    { value: "LA", label: "Louisiana" },
    { value: "ME", label: "Maine" },
    { value: "MD", label: "Maryland" },
    { value: "MA", label: "Massachusetts" },
    { value: "MI", label: "Michigan" },
    { value: "MN", label: "Minnesota" },
    { value: "MS", label: "Mississippi" },
    { value: "MO", label: "Missouri" },
    { value: "MT", label: "Montana" },
    { value: "NE", label: "Nebraska" },
    { value: "NV", label: "Nevada" },
    { value: "NH", label: "New Hampshire" },
    { value: "NJ", label: "New Jersey" },
    { value: "NM", label: "New Mexico" },
    { value: "NY", label: "New York" },
    { value: "NC", label: "North Carolina" },
    { value: "ND", label: "North Dakota" },
    { value: "OH", label: "Ohio" },
    { value: "OK", label: "Oklahoma" },
    { value: "OR", label: "Oregon" },
    { value: "PA", label: "Pennsylvania" },
    { value: "RI", label: "Rhode Island" },
    { value: "SC", label: "South Carolina" },
    { value: "SD", label: "South Dakota" },
    { value: "TN", label: "Tennessee" },
    { value: "TX", label: "Texas" },
    { value: "UT", label: "Utah" },
    { value: "VT", label: "Vermont" },
    { value: "VA", label: "Virginia" },
    { value: "WA", label: "Washington" },
    { value: "WV", label: "West Virginia" },
    { value: "WI", label: "Wisconsin" },
    { value: "WY", label: "Wyoming" },
  ];

  useEffect(() => {
    async function fetchUsersData() {
      const users = await axios.get(
        "https://services.newleey.com/api/user/all-users"
      );
      setAllUsers(users.data);
    }

    fetchUsersData();
  }, []);

  const handleSelectedLeadRemoveFromUser = (event) => {
    setSelectedLeadRemoveFromUser(event.target.value);
  };

  const handleAddPremiumLeadCredit = async () => {
    const token = sessionStorage.getItem("token");

    const headers = {
      Authorization: token,
    };

    const body = {
      user_id: selectedUserAddPremium,
      amount: premiumLeadCredit,
    };

    try {
      const response = await axios.patch(
        `https://services.newleey.com/api/user/add-premium-leads-credit`,
        body,
        { headers: headers }
      );
      if (response.status === 200) {
        setAddLeadMessage("Premium Lead Credit Added Successfully");
        setShowSuccess(true);
      }
    } catch (error) {
      console.error(error);
      setAddLeadMessage("Error adding premium lead credit");
      setShowSuccess(true);
    }
  };

  const fetchPremiumLeadInfo = async (user_id) => {
    try {
      // Grab token from sessionStorage
      const token = sessionStorage.getItem("token");

      // Set up headers with Authorization
      const headers = {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      };

      const userPremiumLeads = await axios.post(
        "https://services.newleey.com/api/user/purchased_leads",
        { user_id: user_id },
        { headers: headers }
      );
      const response = await axios.post(
        "https://services.newleey.com/api/leads/premium_info",
        { user_id: user_id, ids: userPremiumLeads.data },
        { headers }
      );
      if (response.status === 200) {
        console.log(response.data);
        setPremiumUserLeads(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setShowSuccess(false);
  };

  const handleRemoveLeadFromUser = async () => {
    const token = sessionStorage.getItem("token");

    const headers = {
      Authorization: token,
    };

    try {
      // Make an API request to remove the selected lead from the selected user
      const response = await axios.delete(
        "https://services.newleey.com/api/user/remove-lead",
        {
          headers: headers,
          data: {
            user_id: selectedUserRemoveLead,
            lead_id: selectedLeadRemoveFromUser,
          },
        }
      );

      if (response.status === 200) {
        console.log(response.data);
        setAddLeadMessage("Lead removed from user successfully");
        setShowSuccess(true);
        setSelectedLeadRemoveFromUser(""); // Clear the selected lead ID
      }
    } catch (error) {
      console.error(error);
      setAddLeadMessage("Error removing lead from user");
      setShowSuccess(true);
    }
  };

  const handleSelectedUserRemoveLead = (event) => {
    setSelectedUserRemoveLead(event.target.value);
    setSelectedLeadToRemove(null);
    fetchPremiumLeadInfo(event.target.value);
  };

  const handleSelectedUserAddPremium = (event) => {
    setSelectedUserAddPremium(event.target.value);
  };

  const handleRemoveLead = async () => {
    const token = sessionStorage.getItem("token");

    const headers = {
      Authorization: token,
    };

    try {
      // Make an API request to remove the selected lead
      const response = await axios.post(
        `https://services.newleey.com/api/leads/deactivate`,
        { headers: headers, leadId: selectedLeadId }
      );

      if (response.status === 200) {
        setAddLeadMessage("Lead deactivated successfully");
        dispatch(fetchLeads());
        setShowSuccess(true);
        setSelectedLeadId("");
      }
    } catch (error) {
      console.error(error);
      setAddLeadMessage("Error deactivating lead");
      setShowSuccess(true);
    }
  };

  const handleSelectLead = (event) => {
    setSelectedLeadId(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = sessionStorage.getItem("token");

    const headers = {
      Authorization: token,
    };

    const body = {
      leadType: leadType,
      dealType: dealType,
      city: city,
      state: state,
      zip: postalCode,
      leadTemp: leadTemperature,
      additional_information: additionalInfo,
      address: addressLine1,
      active: true,
      leadContactName: leadContactName,
      leadContactEmail: leadContactEmail,
      leadContactPhone: leadContactPhone,
    };

    try {
      const response = await axios.post(
        "https://services.newleey.com/api/leads/new",
        body,
        { headers: headers }
      );
      if (response.status === 200) {
        setAddLeadMessage("Lead added successfully");
        setShowSuccess(true);
        // Clear form input values after successful lead addition
        setAddressLine1("");
        setCity("");
        setState("");
        setPostalCode("");
        setLeadType("");
        setLeadTemperature("");
        setDealType("");
        setAdditionalInfo("");
        setLeadContactName("");
        setLeadContactEmail("");
        setLeadContactPhone("");
      }
      return response.data;
    } catch (error) {
      console.error(error);
      setAddLeadMessage(
        "Error adding lead, please check the validity of your ZIP code"
      );
      setShowSuccess(true);
    }
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        bgcolor: "background.default",
        p: 2,
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      <Toolbar />
      <CardHeader title="Add New Lead" />
      <Card sx={{ p: 2 }} variant="outlined">
        <form onSubmit={handleSubmit}>
          <TextField
            label="Address Line 1"
            fullWidth
            sx={{ mb: 2 }}
            value={addressLine1}
            onChange={(e) => setAddressLine1(e.target.value)}
          />
          <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
            <TextField
              label="City"
              fullWidth
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <FormControl fullWidth>
              <InputLabel id="state-label">State</InputLabel>
              <Select
                labelId="state-label"
                id="state"
                value={state}
                label="State"
                onChange={(e) => setState(e.target.value)}
              >
                {statesList.map((stateOption) => (
                  <MenuItem key={stateOption.value} value={stateOption.value}>
                    {stateOption.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Postal Code"
              fullWidth
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
            />
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            <TextField
              label="Lead Type"
              select
              fullWidth
              sx={{ mb: 2 }}
              value={leadType}
              onChange={(e) => setLeadType(e.target.value)}
            >
              <MenuItem value="SFH">SFH</MenuItem>
              <MenuItem value="Multifamily">Multifamily</MenuItem>
              <MenuItem value="Land">Land</MenuItem>
              <MenuItem value="Mobile Home">Mobile Home</MenuItem>
            </TextField>
            <TextField
              label="Lead Temperature"
              select
              fullWidth
              sx={{ mb: 2 }}
              value={leadTemperature}
              onChange={(e) => setLeadTemperature(e.target.value)}
            >
              <MenuItem value="Hot">Hot</MenuItem>
              <MenuItem value="Cold">Cold</MenuItem>
              <MenuItem value="Old">Old</MenuItem>
              <MenuItem value="Real Estate">Real Estate Agent</MenuItem>
            </TextField>
            <TextField
              label="Deal Type"
              select
              fullWidth
              sx={{ mb: 2 }}
              value={dealType}
              onChange={(e) => setDealType(e.target.value)}
            >
              <MenuItem value="Foreclosure">Foreclosure</MenuItem>
              <MenuItem value="Inheritance">Inheritance</MenuItem>
              <MenuItem value="Financial Difficulties">
                Financial Difficulties
              </MenuItem>
              <MenuItem value="Sell Without Agent">
                Sell Without Real Estate Agent
              </MenuItem>
              <MenuItem value="QuickSale">Looking for a Quick Sale</MenuItem>
              <MenuItem value="Distressed Property">
                Distressed Property
              </MenuItem>
              <MenuItem value="Emergency Reasons">Emergency Reasons</MenuItem>
              <MenuItem value="Divorce">Divorce</MenuItem>
              <MenuItem value="List Type">List Type</MenuItem>
            </TextField>
          </Box>
          <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  value={leadContactName}
                  onChange={(e) => setLeadContactName(e.target.value)}
                  label="Lead Contact Name"
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  value={leadContactEmail}
                  onChange={(e) => setLeadContactEmail(e.target.value)}
                  label="Lead Contact Email"
                  fullWidth
                ></TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  value={leadContactPhone}
                  onChange={(e) => setLeadContactPhone(e.target.value)}
                  label="Lead Contact Phone Number"
                  fullWidth
                ></TextField>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
            <TextField
              label="Additional Information"
              fullWidth
              multiline
              rows={6}
              value={additionalInfo}
              onChange={(e) => setAdditionalInfo(e.target.value)}
            />
          </Box>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </form>
      </Card>
      <Box>
        <CardHeader title="Deactivate a Lead" />
        <Card
          sx={{
            p: 2,
          }}
          variant="outlined"
        >
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Grid item xs={8}>
              <FormControl fullWidth>
                <TextField
                  select
                  value={selectedLeadId}
                  onChange={handleSelectLead}
                  label="Select Lead To Remove"
                >
                  {leads.map((lead) => (
                    <MenuItem key={lead._id} value={lead._id}>
                      <Box>
                        <Typography>{`${lead.city}, ${lead.state} ${lead.zip}`}</Typography>
                        <Typography>{`Deal Type: ${lead.dealType}`}</Typography>
                        <Typography>{`Lead Temp: ${lead.leadTemp}`}</Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleRemoveLead}
              >
                Remove Lead
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Box>
      <Box>
        <CardHeader title="Remove a Lead From a User" />
        <Card sx={{ p: 3 }} variant="outlined">
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Grid item xs={4}>
              <FormControl fullWidth>
                <TextField
                  select
                  value={selectedUserRemoveLead}
                  onChange={(e) => handleSelectedUserRemoveLead(e)}
                  label="Select User"
                >
                  {allUsers.map((user) => (
                    <MenuItem key={user._id} value={user._id}>
                      <Box>
                        <Typography>{user.email}</Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <TextField
                  select
                  value={selectedLeadRemoveFromUser}
                  label="Select Lead to Remove From User"
                  onChange={(e) => handleSelectedLeadRemoveFromUser(e)}
                >
                  {premiumUserLeads &&
                    Object.entries(premiumUserLeads).map(([leadId, lead]) => (
                      <MenuItem key={leadId} value={lead}>
                        <Box>{lead.address}</Box>
                      </MenuItem>
                    ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleRemoveLeadFromUser}
              >
                Remove Lead From User
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Box>
      <Box>
        <CardHeader title="Add Premium Lead Credit to User" />
        <Card sx={{ p: 3 }} variant="outlined">
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Grid item xs={4}>
              <FormControl fullWidth>
                <TextField
                  type="number"
                  value={premiumLeadCredit}
                  onChange={(e) => setPremiumLeadCredit(e.target.value)}
                  label="Add Premium Lead Credit to User"
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <TextField
                  select
                  value={selectedUserAddPremium}
                  onChange={handleSelectedUserAddPremium}
                  label="Select User"
                >
                  {allUsers.map((user) => (
                    <MenuItem key={user._id} value={user._id}>
                      <Box>
                        <Typography>{user.email}</Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleAddPremiumLeadCredit}
              >
                Add Premium Lead Credit to User
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Box>
      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={handleClose}
        message={addLeadMessage}
      />
    </Box>
  );
};

export default Administrative;
