import React, { useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  TextField,
  Button,
  Typography,
  Stack,
  Card,
  Link,
  CircularProgress,
  Divider,
} from "@mui/material";

import { GoogleLogin } from "@react-oauth/google";
import VanillaLogin from "./VanillaLogin";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import logo from "../../images/newleey_text_logo.webp";
import {
  initialUserData
} from "../../redux/auth/authSlice";

function Login(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNewUser = useSelector((state) => state.auth.isNewUser);

  const [userClickedGoogleSignIn, setUserClickedGoogle] = useState(false);

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    console.log(credentialResponse);
    if (credentialResponse.credential) {
      sessionStorage.setItem("token", credentialResponse.credential);

      await dispatch(initialUserData())
        .unwrap()
        .then((response) => {
          if (response.onboarded === false) {
            navigate("/onboarding");
          } else {
            setTimeout(() => {
              navigate("/");
            }, 3000);
          }
        });
    }
  };

  return (
    <Box
      sx={{
        flex: "1 1 auto",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        height: "98vh",
        backgroundColor: "#2D3DFF",
      }}
    >
      <Box
        sx={{
          maxWidth: 650,
          px: 3,
          py: "100px",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={logo} alt="Newleey Logo" style={{ width: "60%" }} />
        </Box>
        <Card
          sx={{
            p: 3,
            mt: "60px",
          }}
        >
          <Stack spacing={1} sx={{ mb: 3, alignItems: "center", gap: 2 }}>
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              Login
            </Typography>
            <VanillaLogin />
            <Divider sx={{ width: "100%" }}>
              <Typography variant="body2" sx={{ textAlign: "center" }}>
                or continue with Google
              </Typography>
            </Divider>
            {userClickedGoogleSignIn ? (
              <CircularProgress />
            ) : (
              <GoogleLogin
                auto_select={true}
                click_listener={() => {
                  setUserClickedGoogle(true);
                }}
                onSuccess={handleGoogleLoginSuccess}
                onError={() => {
                  console.log("Login Failed");
                }}
              />
            )}
          </Stack>
        </Card>
      </Box>
    </Box>
  );
}

export default Login;
