// leadSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Create an async thunk to fetch leads
export const fetchLeads = createAsyncThunk("leads/fetchLeads", async () => {
  const token = sessionStorage.getItem("token");

  const config = {
    headers: {
      authorization: token,
    },
  };

  try {
    const response = await axios.get(
      "https://services.newleey.com/api/leads/all",
      config
    );
    return response.data;
  } catch (error) {
    throw error;
  }
});

const leadsSlice = createSlice({
  name: "leads",
  initialState: {
    leads: [],
    filteredLeads: [],
    status: "false",
    error: null,
    selectedCardId: null,
    allLeadStates: [],
    allLeadDealTypes: [],
    allLeadTypes: [],
  },
  reducers: {
    selectCard: (state, action) => {
      state.selectedCardId = action.payload.cardId;
    },
    setFilteredLeads: (state, action) => {
      const filters = action.payload.filters;

      console.log("Original leads:", state.leads);
      console.log("Filters:", filters);

      // Reset filteredLeads to all leads if no filters are provided
      if (!filters || Object.keys(filters).length === 0) {
        state.filteredLeads = state.leads;
        console.log("Filtered leads:", state.filteredLeads);
        return;
      }

      // Convert filters object into an array of filter functions
      const filterFunctions = Object.entries(filters)
        .map(([field, value]) => {
          // Check for 'All' filter and handle accordingly
          if (value === "All") {
            return null; // Exclude this filter
          }

          // Exclude filters with empty values
          if (value !== "") {
            return (lead) => lead[field] === value;
          }

          return null; // Exclude this filter
        })
        .filter(Boolean); // Remove null values

      console.log("Filter functions:", filterFunctions);

      // Apply filters consecutively
      state.filteredLeads = state.leads.filter((lead) => {
        // Include all values for the 'All' filter
        if (filterFunctions.length === 0) {
          return true;
        }

        // Apply filters even if there's only one
        return filterFunctions.every((filterFunction) => filterFunction(lead));
      });

      console.log("Filtered leads:", state.filteredLeads);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeads.pending, (state) => {
        state.status = "loading";
        console.log("Loading leads...");
      })
      .addCase(fetchLeads.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.leads = action.payload;
        state.filteredLeads = action.payload;
        state.allLeadStates = [
          ...new Set(action.payload.map((lead) => lead.state)),
        ];
        state.allLeadDealTypes = [
          ...new Set(action.payload.map((lead) => lead.dealType)),
        ];
        state.allLeadTypes = [
          ...new Set(action.payload.map((lead) => lead.leadType)),
        ];
      })
      .addCase(fetchLeads.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const selectAllLeads = (state) => state.leads.leads;
export const selectFilteredLeads = (state) => state.leads.filteredLeads; // New selector
export const selectLeadsStatus = (state) => state.leads.status;
export const selectLeadsError = (state) => state.leads.error;
export const selectAllLeadStates = (state) => state.leads.allLeadStates;
export const selectAllLeadDealTypes = (state) => state.leads.allLeadDealTypes;
export const selectAllLeadTypes = (state) => state.leads.allLeadTypes;

export const { selectCard, setFilteredLeads } = leadsSlice.actions; // New action
export const selectSelectedCardId = (state) => state.leads.selectedCardId;

export default leadsSlice.reducer;
