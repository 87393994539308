import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Grow,
  Box,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  SvgIcon,
  CardHeader,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Card,
  IconButton,
  Collapse,
  Paper,
  Grid,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useDispatch, useSelector } from "react-redux";
import {
  getPurchasedLeads,
  fetchPurchasedLeads,
} from "../../../redux/auth/authSlice";
import { selectAllLeads } from "../../../redux/leadSlice";

function PurchasedLeads() {
  const dispatch = useDispatch();

  const [selectedLead, setSelectedLead] = useState(null);
  const [isDetailsOpen, setIsDetailsOpen] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [premiumLeadsInfo, setPremiumLeadsInfo] = useState({});

  const purchasedLeads = useSelector(getPurchasedLeads);
  const leads = useSelector(selectAllLeads);
  const userId = useSelector((state) => state.auth.user_id);

  useEffect(() => {
    dispatch(fetchPurchasedLeads());
    fetchPremiumLeadInfo();
  }, []);

  const handleTemp = (temp) => {
    if (temp === "Cold") {
      return "#cbe7f7";
    } else if (temp === "Hot") {
      return "#f2786f";
    }
    return "#c9c9c9";
  };

  const handleTableCellClick = (leadIndex) => {
    setIsDetailsOpen((prevDetails) => {
      const newDetails = { ...prevDetails };
      newDetails[leadIndex] = !newDetails[leadIndex];
      return newDetails;
    });
  };

  const fetchPremiumLeadInfo = async () => {
    try {
      // Grab token from sessionStorage
      const token = sessionStorage.getItem("token");

      // Set up headers with Authorization
      const headers = {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.post(
        "https://services.newleey.com/api/leads/premium_info",
        { user_id: userId, ids: purchasedLeads },
        { headers }
      );
      if (response.status === 200) {
        setPremiumLeadsInfo(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filteredLeads = purchasedLeads
    .map((leadId) => premiumLeadsInfo[leadId])
    .filter(
      (lead) =>
        lead &&
        (searchValue === "" ||
          lead.city.toLowerCase().includes(searchValue.toLowerCase()) ||
          lead.dealType.toLowerCase().includes(searchValue.toLowerCase()))
    );

  return (
    <Grow in>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: 2,
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <Toolbar />
        <CardHeader title="Purchased Leads" />
        <Card sx={{ p: 2 }} variant="outlined">
          <OutlinedInput
            fullWidth
            placeholder="Search Purchased Leads"
            startAdornment={
              <InputAdornment position="start">
                <SvgIcon color="action" fontSize="small">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            }
            sx={{ maxWidth: 500 }}
            defaultValue=""
            onChange={(event) => {
              setSearchValue(event.target.value);
            }}
          />
        </Card>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Expand</TableCell>
              <TableCell>City</TableCell>
              <TableCell>Contact Name</TableCell>
              <TableCell sortDirection="desc">Date</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredLeads.reverse().map((lead, index) => (
              <React.Fragment key={index}>
                <TableRow onClick={() => handleTableCellClick(index)}>
                  <TableCell>
                    {isDetailsOpen[index] ? (
                      <IconButton size="small">
                        <ExpandLessIcon />
                      </IconButton>
                    ) : (
                      <IconButton size="small">
                        <ExpandMoreIcon />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>{lead.city}</TableCell>
                  <TableCell>
                    {lead.leadContactName ? lead.leadContactName : "Loading"}
                  </TableCell>
                  <TableCell>
                    {lead.date
                      ? new Date(lead.date).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        })
                      : ""}
                  </TableCell>
                  <TableCell>
                    <Card
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: handleTemp(lead.leadTemp),
                        color: "black",
                        borderRadius: 10,
                        boxShadow: 0,
                      }}
                    >
                      <Typography variant="overline">
                        {lead.leadTemp}
                      </Typography>
                    </Card>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={5}
                    sx={{ paddingBottom: 0, paddingTop: 0 }}
                  >
                    <Collapse
                      in={isDetailsOpen[index]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box sx={{ my: 3 }}>
                        <Paper elevation={0}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Typography variant="overline">
                                Address:{" "}
                              </Typography>
                              <Typography variant="body1">
                                {lead.address}
                              </Typography>
                              <Typography variant="overline">City: </Typography>
                              <Typography variant="body1">{`${lead.city}, ${lead.state}`}</Typography>
                              <Typography variant="overline">
                                Deal Type:{" "}
                              </Typography>
                              <Typography variant="body1">
                                {lead.dealType}
                              </Typography>
                              <Typography variant="overline">
                                Additional Information:
                              </Typography>
                              <Typography variant="body1">
                                {lead.additional_information}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="overline">
                                Contact Name:
                              </Typography>
                              <Typography variant="body1">
                                {lead.leadContactName}
                              </Typography>
                              <Typography variant="overline">
                                Contact Phone:
                              </Typography>
                              <Typography variant="body1">
                                {lead.leadContactPhone}
                              </Typography>
                              <Typography variant="overline">
                                Contact Email:
                              </Typography>
                              <Typography variant="body1">
                                {lead.leadContactEmail}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Grow>
  );
}

export default PurchasedLeads;
