import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Stack,
  Card,
  Stepper,
  Step,
  StepLabel,
  Button,
  Grid,
  CircularProgress,
} from "@mui/material";

import store from "../../redux/store";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GoogleLogin } from "@react-oauth/google";
import { setOnboarded } from "../../redux/auth/authSlice";

import logo from "../../images/newleey_text_logo.webp";

import PaymentVerification from "./PaymentVerification";
import Payment from "./Payment";
function Login(props) {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const isNewUser = useSelector((state) => state.auth.isNewUser);

  const [activeStep, setActiveStep] = useState(0);
  const [userClickedGoogleSignIn, setUserClickedGoogle] = useState(false);
  const [canFinish, setCanFinish] = useState(false);
  const [finished, setFinished] = useState(false);
  const [userClickedSubscribe, setUserClickedSubscribe] = useState(true);

  const userEmail = useSelector((state) => state.auth.email);

  const steps = ["Usage Terms", "Privacy Policy", "Payment", "Welcome Video"];

  useEffect(() => {
    if (activeStep === steps.length) {
      setFinished(true);
    } else {
      setFinished(false);
    }
  }, [activeStep, steps.length]);

  useEffect(() => {
    let isSubscribed = false; // flag to keep track of subscription status
    const token = sessionStorage.getItem("token");

    async function checkPaymentStatus() {
      try {
        const response = await axios.post(
          "https://services.newleey.com/api/user/check-subscription",
          { user_email: userEmail },
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );
        isSubscribed = response.data.subscribed;
        console.log(isSubscribed);

        if (!isSubscribed) {
          // If not subscribed, schedule the next check
          setTimeout(checkPaymentStatus, 5000);
        } else {
          console.log("User is subscribed!");
          setCanFinish(true);
        }
      } catch (error) {
        console.error("Error checking subscription status", error);
        // Optionally, you can retry after some delay if there's an error
        setTimeout(checkPaymentStatus, 3000);
      }
    }

    // Start the first check
    checkPaymentStatus();

    // Cleanup function to clear the timeout if the component unmounts
    return () => {
      isSubscribed = true; // stop the loop if component unmounts
    };
  }, [userClickedSubscribe, userEmail]);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleFinish = async () => {
    const onboarded = await axios.post(
      "https://services.newleey.com/api/user/onboard",
      {
        user_email: userEmail,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    if (onboarded.status === 200) {
      dispatch(setOnboarded(true));
      store.dispatch({ type: "CHECK_TOKEN_ON_LOAD" });
      navigate("/");
    }
  };

  return (
    <Box
      sx={{
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "space-around",
        height: "93vh",
        pt: 6,
        backgroundColor: "#2D3DFF",
      }}
    >
      <Box
        sx={{
          maxWidth: 800,
          width: "100%",
          maxHeight: 600,
          minHeight: 600,
          height: 600,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={logo} alt="Newleey Logo" style={{ width: "60%" }} />
        </Box>
        <Card
          sx={{
            p: 3,
            mt: "60px",
          }}
        >
          <Stack spacing={1} sx={{ mb: 3, alignItems: "center", gap: 2 }}>
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              Welcome to Newleey!
            </Typography>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              Please follow the onboarding steps to get started.
            </Typography>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              sx={{ width: "100%" }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Box>
              {finished ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you're finished!
                  </Typography>
                  <Button onClick={() => navigate("/")} variant="contained">
                    Go to Dashboard
                  </Button>
                </Box>
              ) : (
                <Box>
                  {/* Your content for each step */}
                  {activeStep === 0 && (
                    <Typography sx={{ mt: 2 }}>
                      <Box sx={{ maxHeight: 300, overflowY: "scroll" }}>
                        <Typography variant="p2" sx={{ textAlign: "center" }}>
                          This website (the “Site”) offers information regarding
                          Our services. Newleey.com is a website provided to You
                          by Newleey, LLC and its related, affiliated and/or
                          subsidiary companies (“We”, “Us” or “Our”). The
                          following user Agreement governs Site users’ (“Your”
                          or “You”) access to and use of the Site and any other
                          information available on or through the Site
                          (“Content”). USER AGREEMENT Please read through this
                          user agreement (“Agreement”) before accessing, using,
                          or browsing the Content or before selecting the “I
                          AGREE” button associated with this Content.f By
                          accessing, browsing, or using the Content and/or
                          clicking on “I AGREE,” You, on behalf of Yourself or
                          Your entity, as applicable, agree that: You have read
                          and understand all of the terms of this Agreement; You
                          agree to be bound by all of the terms of this
                          Agreement; This Agreement is the legal equivalent of a
                          written and signed contract between You and Us; and If
                          accessing, browsing, or using Our Content of behalf of
                          an entity, You have full authority to bind Your entity
                          to all of the terms of this Agreement. If You cannot
                          be bound or are not willing to be bound by this
                          Agreement, then do not select the “I Agree” button
                          associated with this Agreement or access, browse, use
                          or access any of the Content. We do not grant You any
                          right or license to access browse or use the Content
                          without Your express consent to abide by the terms of
                          this Agreement. INFORMATIONAL PURPOSES ONLY The Site
                          is not intended to provide You with any financial,
                          real estate, legal or otherwise related advice of any
                          kind and You understand and agree that the Site may
                          include advertisements. Based upon information We
                          obtain, We may make available to You advertisements
                          that may be relevant and/or useful to You based upon
                          Your own review. SUBMISSIONS All comments, concepts,
                          feedback, graphics, ideas, illustrations, remarks,
                          suggestions, and other materials that You communicate
                          to Us (collectively, the “Submissions”) become Our
                          property and You waive any and all rights, except
                          moral rights, if applicable. Further, You hereby
                          forever assign all rights in regard to the
                          Submissions, now known or future existing. We will not
                          be required to treat any Submissions as confidential
                          and will not be liable for any of its business ideas
                          (including and not limited to product or advertising
                          ideas) and will not incur any liability as a result of
                          any similarities (whether actual or perceived) that
                          may or may not appear in future operations or other
                          business ventures. Except as otherwise provided for in
                          this Agreement, We will have full right of use for the
                          Submission for any commercial or other purpose in its
                          sole discretion without compensation to You. Any
                          Submissions You provide are voluntary. You are
                          responsible for the content of the Submissions and
                          agree to defend (at Our sole option and discretion),
                          indemnify and hold Us harmless from any costs,
                          damages, losses, or expenses including attorney’s
                          fees, which We may incur as a result of Your
                          Submissions. You also agree that You are responsible
                          to the full extent under the law for all activities
                          that occur under Your account whether or not You are
                          the individual or other entity who undertakes such
                          activities. This includes any unauthorized access or
                          use of Your account on either a personal or public
                          computer system. We retain the right to review and/or
                          delete faulty, illegal, offensive, suspicious or other
                          inappropriate Submissions that violate the terms and
                          conditions of this Agreement. AGE You must be at least
                          18 years of age to use the Site and any Content. USER
                          ID AND PASSWORDS Access to certain areas of the Site
                          or the Content may be limited by a user identifier
                          ("User ID") and password, which are selected as part
                          of the registering. By registering, You covenant,
                          represent, and warrant that: (i) You are at least 18
                          years of age; (ii) You are using Your actual identity;
                          (iii) You have provided only accurate, complete,
                          current, and true information about Yourself during
                          the registration process; and (iv) You will maintain
                          and promptly update the information that You provide
                          to keep it accurate, complete, current and true. By
                          logging into Your account using any password, You
                          covenant, represent, and warrant that You are
                          authorized to use such password and to engage in the
                          activities that You conduct with the account. You
                          agree that You will be solely responsible for the
                          maintenance and security of Your User ID and password.
                          You also agree that You will be solely responsible for
                          any activities conducted on or through Your
                          subscription account. Your User ID and password are
                          unique to You, and You agree not to disclose or share
                          Your User ID and password. We reserve the right to
                          deny, revoke or prevent access to the Site, or any
                          part thereof, at any time in Our sole discretion, with
                          or without cause. Your access to the Site will
                          terminate upon the termination of this Agreement for
                          any reason. If You wish to cancel a password, or if
                          You become aware of any loss, theft, or unauthorized
                          use of a password, please notify Us immediately. You
                          may choose to access some of the Content of the Site
                          without creating a User ID and password or
                          subscription account. By accessing, using, or browsing
                          the Site or any of the Content, You are agreeing to be
                          bound by all the terms and conditions of this
                          Agreement. MEMBERSHIP AGREEMENT The Site offers
                          optional membership subscriptions (“Memberships”). We
                          may offer a number of subscription plans (“Plans”). If
                          You purchase a Membership your membership will
                          continue and automatically renew until terminated. To
                          purchase a Membership, You must provide us with one or
                          more Payment Methods. "Payment Method" means a
                          current, valid, accepted method of payment, as may be
                          updated from time to time and which may include
                          payment through Your account with a third party. You
                          must cancel your membership before it renews in order
                          to avoid billing of the subscription fees for the next
                          billing cycle to Your Payment Method (see
                          "Cancellation" below). Promotional Offers. We may from
                          time to time offer special promotional offers, plans
                          or memberships (“Offers”). Offer eligibility is
                          determined by Us at Our sole discretion and we reserve
                          the right to revoke an Offer and put Your account on
                          hold in the event that we determine You are not
                          eligible. Members of households with an existing or
                          recent Newleey membership may not be eligible for
                          certain introductory Offers. We may use information
                          such as device ID, method of payment or an account
                          email address used with an existing or recent Newleey
                          membership to determine Offer eligibility. The
                          eligibility requirements and other limitations and
                          conditions will be disclosed when you sign-up for the
                          Offer or in other communications made available to
                          you. Premium Lead Credits. As part of our Plans We may
                          offer redeemable credits for (“Premium Lead Credits”).
                          The amount of Premium Credits available per Plan may
                          vary. If Your Membership includes Premium Credits your
                          Premium Credits will reset periodically. For
                          Memberships that are paid on a monthly basis those
                          credits will be reset after We successfully charge
                          your subscription fee to your Payment Method. For
                          Memberships that are paid on an annual basis Premium
                          Credits will be reset monthly on the monthly
                          anniversary of your Membership plan. Any unused
                          Premium Credits will be forfeited each month and do
                          not roll over into the next cycle. BILLING AND
                          CANCELLATION Billing Cycle. The subscription fee for
                          the Newleey service and any other charges you may
                          incur in connection with your use of the service, such
                          as taxes and possible transaction fees, will be
                          charged to your Payment Method on the specific payment
                          date indicated on the "Account" page. The length of
                          your billing cycle will depend on the type of
                          subscription plan that you choose when you signed up
                          for the service. Subscription fees are fully earned
                          upon payment. In some cases your payment date may
                          change, for example if your Payment Method has not
                          successfully settled, when you change your
                          subscription plan or if your paid subscription began
                          on a day not contained in a given month. Visit the
                          Newleey.com website and click on the "Billing details"
                          link on the "Account" page to see your next payment
                          date. We may authorize your Payment Method in
                          anticipation of subscription or service-related
                          charges through various methods, including authorizing
                          it up to approximately one month of service as soon as
                          you register. If you signed up for a Newleey
                          Membership using your account with a third party as a
                          Payment Method, you can find the billing information
                          about your Newleey subscription by visiting your
                          account with the applicable third party. Payment
                          Methods. To receive the Membership benefits you must
                          provide one or more Payment Methods. You authorize us
                          to charge any Payment Method associated with your
                          account in case your primary Payment Method is
                          declined or no longer available to us for payment of
                          your subscription fee. You remain responsible for any
                          uncollected amounts. If a payment is not successfully
                          settled, due to expiration, insufficient funds, or
                          otherwise, and you do not cancel your account, we may
                          suspend your access to the service until we have
                          successfully charged a valid Payment Method. For some
                          Payment Methods, the issuer may charge you certain
                          fees, such as foreign transaction fees or other fees
                          relating to the processing of your Payment Method.
                          Check with your Payment Method service provider for
                          details. Updating your Payment Methods. You can update
                          your Payment Methods by going to the "Account" page.
                          We may also update your Payment Methods using
                          information provided by the payment service providers.
                          Following any update, you authorize us to continue to
                          charge the applicable Payment Method(s). Cancellation.
                          You can cancel your Membership at any time, and you
                          will continue to have access to the membership
                          services through the end of your billing period. To
                          cancel, go to the "Account" page on our Newleey.com
                          website and follow the instructions for cancellation.
                          If you cancel your membership, your Membership will
                          automatically close at the end of your current billing
                          period. To see when your Membership will close, click
                          "Billing details" on the "Account" page. If you signed
                          up for Newleey using your account with a third party
                          as a Payment Method and wish to cancel your Newleey
                          membership, you may need to do so through that third
                          party, for example by visiting your account with the
                          applicable third party and turning off auto-renew, or
                          unsubscribing from the Newleey service through that
                          third party. Changes to the Price and Subscription
                          Plans. We may change our Membership subscription plans
                          and the price of our Plans from time to time. We will
                          notify you at least one month before any price changes
                          or changes to your subscription plan will become
                          effective. If you do not wish to accept the price
                          change or change to your subscription plan, you can
                          cancel your membership before the change takes effect.
                          Refunds. You have THREE (3) DAYS after your Payment
                          Method is successfully billed to request a refund
                          (“Refund Period”) for that transaction. Any request
                          for a refund must be received in writing by contacting
                          us via email at support@Newleey.com or via the Contact
                          Us form. Any refund request received after the Refund
                          Period or not made in writing will not be honored. In
                          addition, if You use any Premium lead credits after
                          your Payment Method is charged then your subscription
                          fee will immediately become nonrefundable. There are
                          no refunds or credits for partially used subscription
                          periods. Following any cancellation, however, you will
                          continue to have access to the service through the end
                          of your current billing period. At any time, and for
                          any reason, we may provide a refund, discount, or
                          other consideration to some or all of our members
                          ("credits"). The amount and form of such credits, and
                          the decision to provide them, are at our sole and
                          absolute discretion. The provision of credits in one
                          instance does not entitle you to credits in the future
                          for similar instances, nor does it obligate us to
                          provide credits in the future, under any circumstance.
                          THIRD-PARTY SERVICES The Site includes links to
                          third-party products, services and websites, and may
                          include functionality that allows for the distribution
                          of your personal information (collectively, your “User
                          Information”) to third parties not under Our control
                          (each, a “Third-Party Provider”). Third-Party
                          Providers are solely responsible for their services.
                          You are responsible for your use and submission of
                          User Information to any third-party, and your dealings
                          or business conducted with any third party arising in
                          connection with the Site. Your use of third-party
                          sites may be subject to associated third-party terms
                          of use, privacy policies and/or other agreements. We
                          do not endorse and take no responsibility for such
                          products or a Third-Party Provider’s use of your User
                          Information. By using a tool that allows for User
                          Information to be transferred, you agree that We may
                          transfer the applicable User Information or other
                          information to the applicable Third-Party Providers.
                          If you submit a contact form or otherwise indicate
                          your interest in contacting a Third-Party Provider,
                          you may receive telemarketing from the Third-Party
                          Provider using the contact information you provided.
                          Third-Party Providers may keep your contact
                          information and any other information received by the
                          Third-Party Provider in processing a contact or other
                          request form. We are not responsible for any damages
                          or costs of any type arising out of or in any way
                          connected with your dealings with any other person.
                          The Site contains services where you may be connected
                          with a real estate professional or other that provides
                          mortgage loan origination services, title and escrow
                          services, or other settlement services. You authorize
                          us to make such referral or lead sale and acknowledge
                          that we may be paid valuable consideration for
                          facilitating such connection. NOTIFICATION FOR GOOGLE
                          MAPS USE Google Maps features and content are utilized
                          on the Site and Your use of the Site with Google Maps
                          features and content is subject to the then-current
                          versions of the: (1) Google Maps/Google Earth
                          Additional Terms of Service at
                          https://maps.google.com/help/terms_maps.html; and (2)
                          Google Privacy Policy at
                          https://www.google.com/policies/privacy/; and (3)
                          Google’s Terms of Service at
                          https://policies.google.com/terms?hl=en SITE
                          MONITORING We may monitor or review any areas on the
                          Site where You or other users convey, transmit or post
                          communications or communicate solely with each other
                          for the purpose of promoting its efficiency,
                          usefulness, and suitability of use. However, We do not
                          undertake to screen or review every post,
                          communication, or Submissions; and We disclaim any
                          liability related to the Content of any post,
                          communication or Submissions, whether or not arising
                          under the laws of copyright, libel, obscenity,
                          privacy, trademark or otherwise. While We may monitor
                          the Content of the communications from time to time,
                          We do not agree with, encourage, endorse, sanction,
                          support, verify the comments, opinions or Submissions
                          posted on the Site. Any information or material placed
                          online, including advice and opinions, are solely the
                          views and responsibility of those posting such
                          statements, and do not necessarily represent the views
                          of Us. We, in Our sole discretion, reserve all rights
                          to discontinue this service generally or to any user
                          at any time. We may disclose any records, electronic
                          communications, information, materials or other
                          Content of any kind (i) if We believes in good faith
                          that the law or legal process requires it; (ii) if
                          such disclosure is necessary or appropriate to operate
                          the Site; or (iii) to protect the rights or property
                          of the Site, its users or business partners. However,
                          We are not responsible for editing, monitoring,
                          policing or screening the Site. INTERACTIVE AREAS We
                          may, at Our discretion, edit, monitor, or review any
                          areas of the Site where You transmit or post
                          communications or communicate with Us or other users,
                          including, but not limited to blogs, bulletin boards,
                          chat rooms, forums, instant messaging sessions,
                          newsgroups and other user-interactive areas
                          (collectively "User-Interactive Areas"), and the
                          Content or any such communications. We, however, will
                          have no liability related to the Content of any such
                          communications, whether or not arising under laws of
                          copyright, libel, obscenity, privacy, trademark or
                          otherwise. If You submit any material to any of the
                          User-Interactive Areas, You agree and represent that
                          Your material: (i) is not offensive, obscene, or
                          otherwise contrary to the laws of where such
                          User-Interactive Areas may be accessed; (ii) is the
                          original work of Your authorship or that You have the
                          right to make the Submission without violating the
                          copyright, trademark, patent or other intellectual
                          property rights of any person or entity; and, (iii) is
                          true and truthful and does not constitute libel,
                          slander, invasion of privacy, defamation or any
                          violation of law. We retain the right to remove
                          communications that include any material deemed
                          abusive, offensive, obscene, defamatory, faulty, or
                          otherwise inappropriate. Thus, if notified of an
                          allegation that the Site contains damaging,
                          defamatory, electronic communications, illegal or
                          offensive records, information, infringing, materials,
                          or other Content, We may, in Our sole discretion,
                          investigate the allegation and determine whether to
                          remove or request the removal of the same from the
                          Site. Notices to Us regarding any alleged copyright
                          infringement should be sent to support@Newleey.com.
                          You may be required to pay fees to access certain
                          features of the Site. All fees shall be in USD and are
                          non-refundable. PRIVACY NOTICE You may find our
                          Privacy Notice here. COPYRIGHT Except as otherwise
                          provide for herein, the Content contained and
                          referenced herein are for informational purposes only.
                          Any reproduction, retransmission or other use is
                          strictly prohibited. If You wish to reproduce any of
                          the Content contained herein, please send e-mail
                          request to support@Newleey.com. Should you believe
                          that Your work has been reproduced on the Site in a
                          way that constitutes copyright infringement you may
                          notify us in accordance with Title 17, United States
                          Code, Section 512(c)(2). TRADEMARKS “Newleey" is Our
                          trademark, and except as provided in this Agreement,
                          We do not grant You any rights thereunder. As such,
                          the Site may not be used in any way varying with the
                          provisions of this Agreement, without Our prior
                          written permission. All other company names, domain
                          names, logos, service marks, trademarks (collectively
                          "Other Trademarks") referred to on the Site are
                          company names, domain names, logos, service marks or
                          trademarks of, or are otherwise the property of Us or
                          Our affiliates or licensors. In countries where any of
                          the company names, domain names, logos, service marks
                          or trademarks are not registered, We claim other
                          rights associated with company names, domain names,
                          logos, service marks or unregistered trademarks. Other
                          company names, domain names, logos, service marks,
                          trademarks referred to on the Site may be trademarks
                          of their respective owners. OTHER INTELLECTUAL
                          PROPERTY RIGHTS All interest, right and title in and
                          to the Content, and all intellectual property rights
                          embodied therein, are the property of Us or Our
                          affiliates, licensors, or suppliers. Without limiting
                          the foregoing, with respect to the Site, Content and
                          Submissions ("Databases") You agree and acknowledge
                          that: The Databases and any other information
                          contained therein shall remain valuable intellectual
                          property owned by, or licensed to Us, and that no
                          proprietary rights are being transferred to You in
                          such materials or in any of the Content contained
                          therein; You will not, either indirectly or directly,
                          use the Databases and any other information contained
                          therein to establish independent data files or
                          otherwise amass statistical information thereof or
                          therefrom; You will not, either indirectly or
                          directly, use the Databases and any other information
                          contained therein in violation of any applicable laws
                          or regulations, including but not limited to the use
                          of automated processes to copy Content from the Site;
                          You shall not publish or distribute in any medium
                          Content, Submissions, Databases, any other information
                          contained therein, and/or summaries or subsets thereof
                          to any person or entity; In the event of any
                          misappropriation or misuse of the Content,
                          Submissions, Databases, and any other information
                          contained therein will cause serious damage to Us and
                          that in such event money damages may not constitute
                          sufficient compensation to Us; consequently; You may
                          access up to 250,000 downloads each calendar month;
                          and In the event of any misappropriation, misuse,
                          and/or by surreptitious means of circumvention (as
                          determined by Us) We shall have the right to
                          terminate/limit/suspend Your access, obtain injunctive
                          relief, and/or obtain any other remedies to which We
                          may be entitled. In addition, You acknowledge that the
                          Content, Submissions, Databases, and any other
                          information contained therein are protected by law,
                          including, but not limited to, United States and
                          international copyright law and trademark law, as well
                          as other state, federal, and international laws and
                          regulations, including contract law, and laws
                          pertaining to privacy and publicity. The names of
                          actual companies, products, and services on the Site
                          may be the trademarks of their respective owners. Any
                          rights not expressly granted herein are reserved. We
                          do not assert copyright or grant any rights to the
                          underlying images or descriptions of real estate
                          listings provided through the Site. Any use of these
                          images and descriptions is subject to the copyright
                          owner’s permission and the requirements of applicable
                          law. NOTICE OF ENFORCEMENT We robustly enforce our
                          intellectual property rights to the fullest extent
                          under the law. Except as specifically contained
                          herein, We will prosecute any unauthorized use or
                          reproduction of any of the Content, Submissions,
                          Databases, and any other information contained therein
                          that violate the protection afforded by the United
                          States and international copyright law and trademark
                          law, and/or other state, federal, and international
                          laws and regulations, including laws pertaining to
                          contracts, and privacy and publicity. LINKS On the
                          Site, links to other internet addresses (“External
                          Sites”) can be accessed. Such External Sites contain
                          information created, maintained, published, or
                          otherwise posted by organizations independent of Our
                          control and/or review. We do not approve, certify,
                          endorse, maintain, or control these External Sites and
                          do not guarantee the accuracy, completeness, efficacy,
                          timeliness, or correct sequencing of information
                          located at such External Sites. We provide the links
                          only as a convenience. Use of any information obtained
                          from such External Sites is voluntary, and reliance on
                          it should only be undertaken after an independent
                          review of its accuracy, completeness, efficacy,
                          timeliness, or correct sequencing of information.
                          Neither the inclusion of the link on the Site nor
                          reference therein to any specific commercial product,
                          process or service by trade name, trademarks, service
                          mark, manufacturer, or otherwise constitutes or
                          implies endorsement, recommendation or favoring by Us.
                          There may be circumstances where access to the Site is
                          provided by a link located at an External Site. We
                          have no responsibility for the content of such other
                          website. We do not make any representations or give
                          any warranties or conditions with respect to any
                          information contained in or at such other External
                          Sites and We shall not be liable for any damages or
                          injury arising from the Content of or access to any
                          External Site. We do not endorse the individuals,
                          companies or other similar entities, or any products
                          or materials associated with such individuals,
                          companies or other similar entities, which provide a
                          link to the Site. Unless approved in writing in
                          advance, You agree not to create any frames at any
                          other websites pertaining to any of the Content
                          located on the Site. DISCLAIMERS THE SITE MAY INCLUDE
                          TECHNICAL INACCURACIES AND/OR TYPOGRAPHICAL ERRORS. WE
                          MAY MAKE CHANGES OR IMPROVEMENTS AT ANY TIME WITHOUT
                          NOTICE. THE SITE IS PROVIDED ON AN "AS IS" AND “AS
                          AVAILABLE” BASIS WITHOUT WARRANTIES OR CONDITIONS OF
                          ANY KIND EITHER EXPRESSED OR IMPLIED. WE DISCLAIM, TO
                          THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, ALL
                          WARRANTIES AND CONDITIONS EXPRESSED OR IMPLIED,
                          INCLUDING WITHOUT LIMITATION, WARRANTIES OF
                          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                          TITLE AND NON-INFRINGEMENT, AND ANY WARRANTIES
                          CONCERNING THE AVAILABILITY, ACCURACY, COMPLETENESS,
                          CURRENCY, RELIABILITY, QUALITY, PERFORMANCE OR
                          SUITABILITY OF THIS SITE, THE CONTENT, OR ANY
                          PRODUCTS, SERVICES OR RESULTS OBTAINED ON OR THROUGH
                          THE SITE. WE DO NOT REPRESENT OR WARRANT THE
                          AVAILABILITY OF THE SITE, THAT YOUR USE OF THE SITE
                          WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL
                          BE CORRECTED, OR THAT WE OR THE SERVER THAT MAKES IT
                          AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
                          COMPONENTS. WE DO NOT WARRANT OR MAKE ANY
                          REPRESENTATIONS REGARDING THE USE OF OR THE RESULT OF
                          THE USE OF THE SITE AND/OR THE CONTENT IN TERMS OF
                          THEIR ACCURACY, APPLICABILITY, CORRECTNESS,
                          TIMELINESS, RELIABILITY OR OTHERWISE. YOU (AND NOT US)
                          ASSUME THE ENTIRE COST OF ALL NECESSARY ACTIONS
                          RESULTING FROM THE USE OF THE SITE. WE ASSUME NO
                          RESPONSIBILITY FOR ANY RESULT OR CONSEQUENCE RELATED
                          DIRECTLY OR INDIRECTLY TO ANY ACTION OR INACTION THAT
                          YOU TAKE BASED UPON INFORMATION FROM THE SITE. THE
                          ABOVE EXCLUSION MAY NOT APPLY TO YOU, TO THE EXTENT
                          THAT APPLICABLE LAW MAY NOT ALLOW THE EXCLUSION OF
                          IMPLIED WARRANTIES. IN ADDITION TO THE FOREGOING, YOU
                          AGREE THAT YOUR USE OF THE SITE AND THE CONTENT IS AT
                          YOUR OWN RISK. LIMITATIONS ON LIABILITY WE WILL NOT BE
                          LIABLE FOR ANY DAMAGES OR INJURY CAUSED BY YOUR USE OF
                          THE SITE AND THE CONTENT, INCLUDING BUT NOT LIMITED
                          TO, ANY COMPUTER VIRUS, DEFECT, DELAY IN OPERATION OF
                          TRANSMISSION, ERROR, FAILURE OF PERFORMANCE,
                          INTERRUPTION, OMISSION, OR LINE FAILURE. WE WILL NOT
                          BE LIABLE FOR ANY DAMAGES OR INJURY, INCLUDING BUT NOT
                          LIMITED TO, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE,
                          EXEMPLARY, CONSEQUENTIAL DAMAGES, LOST PROFITS AND
                          DAMAGES THAT RESULT FROM INCONVENIENCE, DELAY, OR LOSS
                          OF USE THAT RESULT FROM THE USE OF, THE INABILITY TO
                          USE, OR RELIANCE UPON, THE SITE, EVEN IF THERE IS
                          NEGLIGENCE BY US OR BY AN AUTHORIZED REPRESENTATIVE,
                          OR AN AUTHORIZED REPRESENTATIVE HAS BEEN ADVISED OF
                          THE POSSIBILITY OF SUCH DAMAGES, OR ANY OF THE ABOVE.
                          THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU
                          TO THE EXTENT THAT APPLICABLE LAW MAY NOT ALLOW THE
                          LIMITATION OR EXCLUSION OF LIABILITY FOR SUCH DAMAGES.
                          OUR TOTAL LIABILITY TO YOU FOR LOSSES, DAMAGES AND
                          CAUSES OF ACTION WILL NOT BE GREATER THAN THE AMOUNT
                          YOU PAID TO US, IF ANY, TO ACCESS THE SITE. INDEMNITY
                          YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS US,
                          OUR OFFICERS, DIRECTORS EMPLOYEES, AGENTS, CONTENT
                          PROVIDERS, CUSTOMERS AND SUPPLIERS FROM AND AGAINST
                          ANY AND ALL ATTORNEYS’ FEES, CLAIMS, COSTS AND
                          EXPENSES, DAMAGES, INJURIES, LIABILITIES, LOSSES,
                          PROCEEDINGS, RELATING TO OR ARISING FROM: YOUR USE OF
                          THE SITE AND ANY OF THE CONTENT, OR ANY PRODUCTS OR
                          SERVICES OBTAINED ON OR THROUGH THE SITE; ANY
                          ARRANGEMENTS YOU MAKE BASED ON THE CONTENT OBTAINED ON
                          OR THROUGH THE SITE; ANY BREACH BY YOU OF THIS USER
                          AGREEMENT; AND ANY ACTIVITY RELATED TO YOUR USE
                          (INCLUDING INFRINGEMENT OF THIRD PARTIES' WORLDWIDE
                          INTELLECTUAL PROPERTY RIGHTS OR NEGLIGENT OR WRONGFUL
                          CONDUCT) BY YOU OR ANY OTHER PERSON ACCESSING THE SITE
                          USING YOUR ACCOUNT. RELEASE FOR FORCE MAJURE You
                          absolve and release Us and Our affiliates from any
                          claim of harm resulting from any cause(s) over which
                          they do not have direct control, including, but not
                          limited to, failure of electronic or mechanical
                          equipment or communication lines, telephone or other
                          interconnect problems, computer viruses or other
                          damaging code or data, unauthorized access, theft,
                          operator errors, severe weather, earthquakes, natural
                          disasters, strikes or other labor problems, wars, or
                          governmental restrictions. CONSENT TO ELECTRONIC
                          COMMUNICATIONS By using the Service, you consent to
                          receiving certain electronic communications from usas
                          further described in our Privacy Policy. Please read
                          our Privacy Policy to learn more about our electronic
                          communications practices. You agree that any notices,
                          agreements, disclosures, or other communications that
                          we send to you electronically will satisfy any legal
                          communication requirements, including that those
                          communications be in writing. NOTICE FOR CALIFORNIA
                          USERS Under California Civil Code Section 1789.3,
                          California Service users are entitled to the following
                          specific consumer rights notice: The Complaint
                          Assistance Unit of the Division of Consumer Services
                          of the California Department of Consumer Affairs may
                          be contacted in writing at 1625 N. Market Blvd., Suite
                          N 112, Sacramento, California 95834, or by telephone
                          at (800) 952-5210. TERM AND TERMINATION This Agreement
                          will take effect at the time You click "I Agree"
                          and/or access, browse or use the Site or any of the
                          Content, and continues in perpetuity unless terminated
                          by You and/or Us. We reserve the right, at any time
                          and for any reason, without notice to You, (i) to deny
                          You access to the Site and any of the Content; (ii) to
                          change, remove or discontinue any of the Content or
                          services available on or through the Site; or (iii) to
                          terminate this Agreement. You may terminate this
                          Agreement at any time by destroying: (a) all of the
                          Content obtained from the Site, including, without
                          limitation, permanently deleting all of the Content
                          from any computer and/or media; and (b) all related
                          documentation and all copies and installations, if
                          any. In addition, and upon Our request, You agree to
                          certify in writing that all the Content has been
                          destroyed. The warranty disclaimers, limitations and
                          exclusions of liability, reservation and grant of
                          property rights and other terms and conditions of this
                          Agreement which by their nature are intended to
                          survive will survive the termination or expiration of
                          this Agreement for any reason. NOTICES Any notice or
                          other document or communication required or permitted
                          hereunder to Us will be deemed to have been duly given
                          only if in writing and delivered by email to
                          support@Newleey.com and receipt acknowledged by Us.
                          Notices to Us shall be deemed delivered when actually
                          received by Us. Any notice or other document or
                          communication required or permitted hereunder to You
                          will be sent to the e-mail address that You provide to
                          Us when You register. Such notice will be deemed
                          received by You the next business day after the e-mail
                          is sent. Should You elect to send or receive e-mail
                          communications of any kind to or from Us, You
                          represent and warrant to Us that Your e-mail service
                          has appropriate and adequate security systems
                          necessary to prevent unauthorized access to outbound
                          or inbound e-mail transmissions. JURISDICTION;
                          COMPLIANCE WITH LAWS Except as described otherwise, We
                          control and operate the Site from the United States of
                          America and make no representation that the Content is
                          appropriate or available for use in other locations.
                          If You use the Site from other locations, You are
                          responsible for compliance with applicable local laws
                          including, without limitation, all export laws. Some
                          of the Content may be subject to export controls
                          imposed by the United States and may not be downloaded
                          or otherwise exported or reexported: (a) into (or to a
                          national or resident of) any country to which the U.S.
                          has placed an embargo, including without limitation,
                          Cuba, Iran, Iraq, Libya, North Korea, Syria or
                          Yugoslavia; (b) to anyone in the U.S. Treasury
                          Department's Specially Designated national list, or
                          (c) to anyone on the U.S. Commerce Department's Table
                          of Denial Orders. If You download or use the Content
                          You represent and warrant that You are not located in,
                          or under the control of, or a national or resident of
                          any such country or on any such list. Access, browsing
                          and use of the Ste, the Content and the terms and
                          conditions of the user Agreement are governed by U.S.
                          federal law and/or the laws of the State of Arizona,
                          excluding any conflict of laws provisions that would
                          refer to and apply the substantive laws of another
                          jurisdiction. ANY LEGAL ACTION OR PROCEEDING RELATING
                          TO YOUR ACCESS TO OR USE OF THE SITE SHALL BE
                          INSTITUTED ONLY IN A STATE OR FEDERAL COURT LOCATED IN
                          THE STATE OF ARIZONA, U.S.A.; YOU AGREE TO SUBMIT TO
                          THE JURISDICTION OF ARIZONA, AND AGREE THAT VENUE IS
                          PROPER IN ARIZONA, THESE COURTS IN ANY SUCH LEGAL
                          ACTION OR PROCEEDING. WAIVER No delay or omission by
                          Us to exercise any right occurring upon any
                          noncompliance on Your part with respect to any of the
                          terms of this Agreement will impair any such right or
                          power or be construed to be a waiver thereof. Any
                          waiver by Us of any of the agreements, covenants, or
                          conditions to be performed by You will not be
                          construed to be a waiver of any succeeding breach
                          thereof, or of any covenant, condition or agreement
                          herein contained. SEVERABILITY Any of the provisions
                          in this Agreement that are found to be unenforceable
                          or invalid, the remainder of the Agreement shall
                          remain in full force. NO PARTNERSHIP The Site is an
                          independent venture; nothing in this Agreement shall
                          be construed to create a partnership, joint venture,
                          or agency relationship between You and Us. CONTINUED
                          USE DEEMED ACCEPTANCE We reserve the right, at Our
                          sole discretion, to change, alter, amend, or otherwise
                          modify this Agreement from time to time. All
                          modifications will be posted on the Site and such
                          modifications will become effective immediately upon
                          the posting thereof. After any such modification
                          becomes effective, We will deem Your continued use of
                          the Site or any of the Content to constitute Your
                          acceptance of and agreement to such modification. It
                          is Your responsibility to review this Agreement on a
                          regular basis to keep yourself informed of any
                          modifications. ENTIRE AGREEMENT This document
                          constitutes the entirety of this Agreement between You
                          and Us and Your use of the Site.
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          pt: 2,
                        }}
                      >
                        <Typography variant="p2" sx={{ textAlign: "center" }}>
                          By clicking next, you agree to the terms and
                          conditions outlined above.
                        </Typography>
                      </Box>
                    </Typography>
                  )}
                  {activeStep === 1 && (
                    <Typography sx={{ mt: 2 }}>
                      <Box sx={{ maxHeight: 300, overflowY: "scroll" }}>
                        <Typography variant="p2" sx={{ textAlign: "center" }}>
                          PRIVACY NOTICE FOR SITE VISITORS The Newleey.com
                          (“We”, “Us” or “Our”) web site (the “Site”) offers
                          information regarding our services. We make this
                          information available to you without requirement that
                          you disclose personally identifiable information to
                          access the Site content. This Privacy Notice states
                          how We collect, use, retain and disclose information
                          for Site visitors. We can be reached via e-mail at
                          support@Newleey.com You can also reach us by telephone
                          This Privacy Notice is effective as of March 1, 2024.
                          CUSTOMER INFORMATION We do not sell our customer list
                          or customer information to any other entities.
                          Customer information such as name, email, address,
                          mailing address, phone number, and billing information
                          is collected for the sole purpose of providing
                          services to our customers and notifying them of
                          improvements to our products and services. From time
                          to time we will send special offers and promotional
                          notices via mail or e-mail to our customers and to
                          prospects who have expressed interest and have
                          requested such information. At any time, customers may
                          unsubscribe or ’opt out’ of such offers and
                          notifications by following the unsubscribe link on the
                          specific offer received or my contacting us as listed
                          here. We recognize the importance of your privacy and
                          our policy is designed to assist you in understanding
                          how we collect, use, and safeguard the personal
                          information you provide to us and to assist you in
                          making informed decisions when using our site. This
                          policy will be continuously assessed against new
                          technologies, business practices, and our customer’s
                          needs and may be updated from time-to-time; please
                          check back regularly as the Site and this Notice may
                          change. What Information Do We Collect? When you visit
                          this Site you may provide us with two types of
                          information: personal information you knowingly choose
                          to disclose that is collected on an individual basis
                          and website use information collected on an aggregate
                          basis as you and others browse our Site. Personal
                          Information You Choose to Provide Registration
                          Information When you register for any of our products,
                          services, or newsletters you will provide us
                          information about yourself. Email Information If you
                          choose to correspond with us through email, we may
                          retain the content of your email messages together
                          with your email address and our responses. We provide
                          the same protections for these electronic
                          communications that we employ in the maintenance of
                          information received by mail and telephone. Website
                          Use Information Similar to other commercial websites,
                          our Site utilizes a standard technology called cookies
                          (see explanation below, What Are Cookies?) and web
                          server log files to collect information about how our
                          Site is used. Information gathered through cookies and
                          web server logs may include the date and time of
                          visits, the pages viewed, time spent at our website,
                          and the websites visited just before and just after
                          our website. How Do We Use the Information That You
                          Provide to Us? Broadly speaking, we use personal
                          information for purposes of administering our business
                          activities, providing the products and services you
                          requested, to process your payment, to monitor the use
                          of the service, our marketing and promotional efforts
                          and improve our content and service offerings, and
                          customize the Site’s content, layout, services and for
                          other lawful purposes. These uses improve our Site and
                          better tailor it to meet your needs. Furthermore, such
                          information may be shared with others on an aggregate
                          basis. Personally identifiable information or business
                          information will not be shared with parties except as
                          required by law. Occasionally, we may also use the
                          information we collect to notify you about important
                          changes to our website, new services, and special
                          offers we think you will find valuable. You may notify
                          us at any time if you do not wish to receive these
                          offers by emailing us at the link provided on the
                          newsletter. SMS Text Messaging If you provide your
                          phone number, you authorize us to send you text
                          messages. Message and data rates may apply to all text
                          messages you receive. Message frequency is no more
                          than one text message per day. If you wish to stop
                          receiving text messages from us, reply STOP to any
                          text message sent. What Are Cookies? A cookie is a
                          very small text document, which often includes an
                          anonymous unique identifier. When you visit a website,
                          that site`s computer asks your computer for permission
                          to store this file in a part of your hard drive
                          specifically designated for cookies. Each website can
                          send its own cookie to your browser if your browser`s
                          preferences allow it, but (to protect your privacy)
                          your browser only permits a Website to access the
                          cookies it has already sent to you, not the cookies
                          sent to you by other sites. Browsers are usually set
                          to accept cookies. However, if you would prefer not to
                          receive cookies, you may alter the configuration of
                          your browser to refuse cookies. If you choose to have
                          your browser refuse cookies, it is possible that some
                          areas of our Site will not function as effectively
                          when viewed by the users. A cookie cannot retrieve any
                          other data from your hard drive or pass on computer
                          viruses. How Do We Use Information We Collect from
                          Cookies? As you visit and browse our website, the Site
                          uses cookies to differentiate you from other users. In
                          some cases, we also use cookies to prevent you from
                          having to log in more than is necessary for security.
                          Cookies, in conjunction with our web server`s log
                          files, allow us to calculate the aggregate number of
                          people visiting our Site and which parts of the site
                          are most popular. This helps us gather feedback to
                          constantly improve our Site and better serve our
                          clients. Cookies do not allow us to gather any
                          personal information about you and we do not
                          intentionally store any personal information that your
                          browser provided to us in your cookies. What are
                          Dynamic Ads? Dynamic Ads are ads that are personalized
                          in real-time to the user. Unlike static ads that show
                          the same content on every impression, Dynamic Ads
                          enable the advertiser to display a unique creative for
                          different users. Dynamic Ads are the only feasible way
                          to scale up the number of creatives per campaign and
                          to personalize them on each impression. What is
                          ReTargeting? The main goal of ReTargeting Ads is to
                          re-engage visitors after they have left the Site.
                          Dynamic ReTargeting Ads are generated based on the
                          actual products or offers viewed on the advertiser’s
                          site. For example, if you’ve visited a retailer’s
                          website and viewed a specific product, you might
                          receive a banner ad containing that product. Rather
                          than showing the same ad to every visitor, Our Dynamic
                          Ad Application will select the most relevant ad copy
                          based on the user’s activity on the Site. JLN Group's
                          Personalized ReTargeting Platform works when an
                          advertiser places our tag on his website. The tag
                          embeds a third-party cookie on the browser of users
                          visiting the Site. Using Real Time Bidding (RTB) Ad
                          Exchanges, it is then possible to focus media buying
                          efforts against those visitors. It is important to
                          note that the cookie itself does not contain any
                          personal identifying information. Our Personalized
                          ReTargeting ads intelligently match the right offering
                          to each visitor based on his previous activities on
                          the Site. Each advertiser using Our services is
                          responsible to limit the number of ad impressions
                          shown to each user in order to ensure that viewers are
                          not overly exposed to specific ads. How Can I Remove
                          Myself from Being ReTargeted? We make removing our
                          tracking cookie easy for any user who wishes to do so.
                          Users that don’t want to see Our Personalized Ads
                          should simply email support@Newleey.com IP Addresses
                          IP addresses are used by your computer every time you
                          are connected to the Internet. Your IP address is a
                          number that is used by computers on the network to
                          identify your computer. IP addresses are automatically
                          collected by Our web server as part of demographic and
                          profile data known as traffic data so that data (such
                          as the Web pages you request) can be sent to you. What
                          About Legally Compelled Disclosure of Information? We
                          may disclose information when legally compelled to do
                          so, in other words, when we, in good faith, believe
                          that the law requires it or for the protection of our
                          legal rights. We may also disclose account information
                          when we have reason to believe that disclosing this
                          information is necessary to identify, contact or bring
                          legal action against someone who may be violating our
                          Terms of Service or to protect the safety of our users
                          and the public. How Can I Request My Data Be Deleted?
                          You may request that We delete data that We process
                          about You, unless We need to retain such data in order
                          to comply with a legal obligation or to establish,
                          exercise or defend legal claims. Users that want to
                          request their data be deleted should simply email
                          support@Newleey.com AFFILIATE INFORMATION Our
                          affiliate information, including address, phone
                          number, e-mail, and Social Security or Tax id number
                          is confidential. This information is collected for the
                          purposes of: 1) generating commission checks using
                          electronic funds transfer and 2) complying with the
                          United States federal tax requirements. This Site
                          contains links to other web sites. Be aware that we
                          are not responsible for the content or privacy
                          practices of other such sites. We encourage our users
                          to be aware when they leave the Site through a link to
                          read the privacy statements of any other site that
                          collects personally identifiable information.
                          INCORPORATION OF GOOGLE PRIVACY POLICY This Site
                          utilizes a Google Maps API and therefore incorporates
                          Google’s privacy policy as contained at
                          https://policies.google.com/privacy CLIENT INFORMATION
                          We are committed to protecting the privacy of Our
                          clients by keeping all of their information private.
                          This includes but is not limited to the privacy of
                          earnings history, the names of property owners who
                          have applied for and/or received the benefits of a
                          workout, mortgage reduction or other restructuring
                          transaction(s), along with identifying addresses,
                          cities or other identifying details which could
                          potentially reveal the identity or location of any
                          person or transaction related to one of our clients.
                          CHILDREN’S ONLINE PRIVACY PROTECTION ACT (COPPA)
                          NOTICE This Site does not collect, use or disclose
                          personal information from children and is not subject
                          to the provisions COPPA.
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          pt: 2,
                        }}
                      >
                        <Typography variant="p2" sx={{ textAlign: "center" }}>
                          By clicking next, you agree to the privacy policy as
                          outlined above.
                        </Typography>
                      </Box>
                    </Typography>
                  )}
                  {activeStep === 2 && (
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Payment
                            price={199}
                            tier={"Basic"}
                            setUserClickedSubscribe={setUserClickedSubscribe}
                            link={`https://buy.stripe.com/3cs6s90JdbSbfjq4gg?&__customer_email=${userEmail}`}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Payment
                            price={349}
                            tier={"Pro"}
                            setUserClickedSubscribe={setUserClickedSubscribe}
                            link={`https://buy.stripe.com/bIYeYF8bFf4n6MU6op?&__customer_email=${userEmail}`}
                          />
                        </Grid>
                      </Grid>
                      <Box
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          gap: 3,
                        }}
                      >
                        <Typography sx={{ textAlign: "center", pt: 3 }}>
                          After completing your payment with Stripe, please wait
                          while we verify your payment.
                        </Typography>
                        {userClickedSubscribe &&
                          (canFinish ? (
                            <Typography sx={{ textAlign: "center" }}>
                              {" "}
                              Payment verified! Click finish to complete your
                              registration.{" "}
                            </Typography>
                          ) : (
                            <CircularProgress sx={{ mb: 2 }} />
                          ))}
                      </Box>
                    </Box>
                  )}
                  {activeStep === 3 && (
                    <iframe
                      width="600px"
                      height="400px"
                      src="https://www.youtube.com/embed/QEBkyGBy9rg"
                      frameborder="0"
                      allow="fullscreen;"
                    ></iframe>
                  )}
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{ display: "flex", justifyContent: "center", pt: 2 }}
                  >
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      variant="contained"
                    >
                      Back
                    </Button>

                    <Button
                      onClick={
                        activeStep === steps.length - 1
                          ? handleFinish
                          : handleNext
                      }
                      variant="contained"
                      disabled={activeStep === steps.length - 2 && !canFinish}
                    >
                      {activeStep === steps.length - 2 ? "Finish" : "Next"}
                    </Button>
                  </Stack>
                </Box>
              )}
            </Box>
          </Stack>
        </Card>
      </Box>
    </Box>
  );
}

export default Login;
