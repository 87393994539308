import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  forwardRef,
} from "react";
import {
  Snackbar,
  SnackbarContent,
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  Grow,
  CircularProgress,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import StarIcon from "@mui/icons-material/Star";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLeads,
  selectFilteredLeads,
  selectLeadsStatus,
  selectLeadsError,
  selectSelectedCardId,
  selectCard,
} from "../../../redux/leadSlice";
import {
  getFavoritedLeads,
  removeFavoriteLead,
  getUserId,
  addFavoriteLead,
  getPremiumLeads,
  fetchPremiumLeads,
  getPurchasedLeads,
  appendPurchasedLeads,
} from "../../../redux/auth/authSlice";
import { getBounds } from "../../../redux/mapSlice";

import axios from "axios";

const iconColor = "#2D3DFF"; // Desired color

const PropertyCard = forwardRef((props, ref) => {
  const mapRef = ref;

  const dispatch = useDispatch();
  const leads = useSelector(selectFilteredLeads);
  const status = useSelector(selectLeadsStatus);
  const userId = useSelector(getUserId);
  const bounds = useSelector(getBounds);
  const error = useSelector(selectLeadsError);
  const selectedCardId = useSelector(selectSelectedCardId);
  const favoritedLeads = useSelector(getFavoritedLeads);
  const premiumLeads = useSelector(getPremiumLeads);
  const purchasedLeads = useSelector(getPurchasedLeads);

  const cardRefs = useRef({});

  const [zoomed, setZoomed] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedConfirmationLeadId, setSelectedConfirmationLeadId] =
    useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // Function to handle card click
  const handleCardClick = (card_id, new_center) => {
    mapRef.current.setCenter({
      lat: new_center.latitude,
      lng: new_center.longitude,
    });
    mapRef.current.setZoom(12);
    dispatch(selectCard({ cardId: card_id }));
  };

  // Function to determine temperature color
  const handleTemp = (temp) => {
    if (temp === "Cold") {
      return "#cbe7f7";
    } else if (temp === "Hot") {
      return "#f2786f";
    } else if (temp === "Real Estate ") return "";
    return "#c9c9c9";
  };

  // Function to determine temperature emoji
  const handleTempEmoji = (temp) => {
    if (temp.toLowerCase() === "cold") {
      return "🧊";
    } else if (temp.toLowerCase() === "hot") {
      return "🔥";
    } else if (temp.toLowerCase() === "real estate") {
      return "🏡";
    }
    return "📆";
  };

  // Fetch leads on component mount
  useEffect(() => {
    if (status === "false") {
      dispatch(fetchLeads());
    }
  }, [dispatch, status]);

  // Set zoomed state to true on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Dispatch the fetchLeads action
        await dispatch(fetchLeads()).unwrap();
        // Handle successful fetch if needed

        // Set zoomed to true after successful fetch
        setZoomed(true);
      } catch (error) {
        console.error("Error fetching leads:", error);
        // Assuming you are using React Router for navigation
        // You can replace this with your preferred navigation method
        if (error.code === "ERR_BAD_REQUEST") {
          // Redirect to /login
          window.location.href = "/login";
        }
      }
    };

    // Call the fetchData function
    fetchData();
  }, [setZoomed]);

  // Scroll selected card into view
  useLayoutEffect(() => {
    if (selectedCardId && cardRefs.current[selectedCardId]) {
      const parentContainer = cardRefs.current[selectedCardId].parentNode;

      if (parentContainer) {
        parentContainer.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [selectedCardId]);

  // Function to handle unfavoriting a lead
  const handleUnfavorite = async (leadId) => {
    try {
      dispatch(removeFavoriteLead({ user_id: userId, lead_id: leadId }));

      // Show Snackbar notification for unfavoriting
      setSnackbarMessage("Lead unfavorited successfully!");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error removing favorite lead:", error);
    }
  };

  // Function to handle favoriting a lead
  const handleFavorite = async (leadId) => {
    try {
      dispatch(addFavoriteLead({ user_id: userId, lead_id: leadId }));

      // Show Snackbar notification for favoriting
      setSnackbarMessage("Lead favorited successfully!");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error add favorite lead:", error);
    }
  };

  // Function to handle buy confirmation
  const handleBuyConfirmation = (leadId) => {
    // Check if the lead has already been purchased
    if (purchasedLeads && purchasedLeads.includes(leadId)) {
      // Display a dialog informing the user that the lead has already been purchased
      setSnackbarMessage(
        "You have already purchased this lead. You can view it in your dashboard."
      );
      setSnackbarOpen(true);
    } else {
      // Continue with the confirmation dialog for purchasing the lead
      dispatch(fetchPremiumLeads());
      setSelectedConfirmationLeadId(leadId);
      setShowConfirmation(true);
    }
  };

  // Function to handle confirmation dialog close
  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  // Function to handle Snackbar close
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Function to handle lead purchase confirmation
  const handleConfirmationConfirm = (leadId) => {
    axios
      .post("https://services.newleey.com/api/leads/purchase", {
        userId: userId,
        leadId: leadId,
      })
      .then((response) => {
        // Check if the response status is 200
        if (response.status === 200) {
          // Show Snackbar on success
          setSnackbarMessage(
            "Lead purchased successfully! You can view this lead in your dashboard."
          );
          setSnackbarOpen(true);
          dispatch(appendPurchasedLeads(leadId));
        } else {
          console.error("Unexpected status code:", response.status);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          // Server responded with 403 - lead has been purchased more than 3 times
          setSnackbarMessage("This lead is no longer available.");
          setSnackbarOpen(true);
        }
        if (error.response && error.response.status === 400) {
          // Server responded with 400 - user doesn't have enough premium leads
          setSnackbarMessage(
            "You don't have enough premium leads. Purchase more in your dashboard."
          );
          setSnackbarOpen(true);
        } else {
          console.error("Error purchasing lead:", error);
        }
      })
      .finally(() => {
        // Close the confirmation dialog
        handleConfirmationClose();
      });
  };

  // Filter leads based on bounds
  const filteredLeads = leads.filter((lead) => {
    const { latitude, longitude } = lead.coordinates;
    const [swLng, swLat, neLng, neLat] = bounds;
    return (
      latitude >= swLat &&
      latitude <= neLat &&
      longitude >= swLng &&
      longitude <= neLng
    );
  });
  return (
    <div>
      {status === "loading" ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={3}>
          {filteredLeads.reverse().map((property, index) => (
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={leads.length > 1 ? 6 : 12}
              key={property._id}
            >
              <Grow in={zoomed}>
                <Card
                  onClick={() =>
                    handleCardClick(property._id, property.coordinates)
                  }
                  ref={(el) => (cardRefs.current[property._id] = el)}
                  sx={{
                    height: "100%", // Set a fixed height for each card,
                    width: "100%",
                    minHeight: "10rem",
                    border:
                      selectedCardId === property._id
                        ? "4px solid"
                        : "2px solid",
                    borderColor:
                      selectedCardId === property._id
                        ? "#2D3DFF"
                        : handleTemp(property.leadTemp),
                    boxShadow: selectedCardId === property._id ? 3 : 1,
                  }}
                >
                  <CardContent>
                    <Typography variant="subtitle1">
                      Date:{" "}
                      {new Date(property.date).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })}
                    </Typography>
                    <Typography variant="subtitle1">
                      Deal Type: {property.dealType}
                    </Typography>
                    <Typography variant="subtitle1">
                      Lead Type: {property.leadType}
                    </Typography>
                    <Typography variant="subtitle1">
                      Location: {property.city}, {property.state}
                    </Typography>
                    <Typography variant="subtitle1">
                      Zip: {property.zip}
                    </Typography>
                  </CardContent>
                  <CardActions
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        {favoritedLeads &&
                        favoritedLeads.includes(property._id) ? (
                          <StarIcon
                            sx={{ color: iconColor, cursor: "pointer" }}
                            onClick={() => handleUnfavorite(property._id)}
                          />
                        ) : (
                          <StarBorderIcon
                            sx={{ color: iconColor, cursor: "pointer" }}
                            onClick={() => handleFavorite(property._id)}
                          />
                        )}
                        {purchasedLeads &&
                        purchasedLeads.includes(property._id) ? (
                          <ShoppingCartIcon
                            sx={{ color: iconColor, cursor: "pointer" }}
                            onClick={() => handleBuyConfirmation(property._id)}
                          />
                        ) : (
                          <AddShoppingCartIcon
                            sx={{ color: iconColor, cursor: "pointer" }}
                            onClick={() => handleBuyConfirmation(property._id)}
                          />
                        )}
                        <SearchIcon
                          style={{ color: iconColor, cursor: "pointer" }}
                          onClick={() =>
                            handleCardClick(property._id, property.coordinates)
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {handleTempEmoji(property.leadTemp)}
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grow>
            </Grid>
          ))}
        </Grid>
      )}

      <Dialog open={showConfirmation} onClose={handleConfirmationClose}>
        <DialogTitle>
          <Typography variant="h5">Do you want to buy this lead?</Typography>
          <Typography variant="overline">
            You have {premiumLeads} premium leads remaining
          </Typography>
        </DialogTitle>
        <DialogContent>
          {selectedConfirmationLeadId &&
            leads.map((lead) => {
              if (lead._id === selectedConfirmationLeadId) {
                return (
                  <React.Fragment key={lead._id}>
                    <Typography variant="body1">
                      Lead Type: {lead.leadType}
                    </Typography>
                    <Typography variant="body1">
                      Location: {lead.city}, {lead.state}
                    </Typography>
                    <Typography variant="body1">Zip: {lead.zip}</Typography>
                    <Typography variant="body1">
                      Deal Type: {lead.dealType}
                    </Typography>
                  </React.Fragment>
                );
              }
              return null;
            })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmationClose}>Cancel</Button>
          <Button
            onClick={() =>
              handleConfirmationConfirm(selectedConfirmationLeadId)
            }
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContent message={snackbarMessage} />
      </Snackbar>
    </div>
  );
});

export default PropertyCard;
