import React, { useState } from "react";
import {
  Box,
  Card,
  CircularProgress,
  Stack,
  Typography,
  Button,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "../../images/newleey_text_logo.webp";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function ResetPassword() {
  const initialValues = {
    email: "",
    resetCode: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    resetCode: Yup.string().required("Reset code is required"),
    newPassword: Yup.string()
      .required("New password is required")
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
        "Password must contain at least one uppercase letter, one number, one symbol, and be at least 6 characters long"
      ),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm new password is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await axios.post(
        "https://services.newleey.com/api/user/password-reset/verify",
        {
          email: values.email,
          resetCode: values.resetCode,
          newPassword: values.newPassword,
        }
      );
      console.log(response.data);
      alert("Password reset successfully!");
      navigate("/login");
    } catch (error) {
      console.error(error);
      alert("An error occurred. Please try again later.");
    } finally {
      setSubmitting(false);
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <Box
      sx={{
        flex: "1 1 auto",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        height: "98vh",
        backgroundColor: "#2D3DFF",
      }}
    >
      <Box sx={{ maxWidth: 650, px: 3, py: "100px", width: "100%" }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={logo} alt="Newleey Logo" style={{ width: "60%" }} />
        </Box>
        <Card sx={{ p: 3, mt: "60px" }}>
          <Stack spacing={2}>
            <Typography variant="h4" align="center">
              Reset Your Password
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Stack spacing={2}>
                    <Field
                      as={TextField}
                      fullWidth
                      name="email"
                      label="Email Address"
                      type="email"
                    />
                    <ErrorMessage name="email">
                      {(msg) => (
                        <Typography variant="body2" color="error">
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                    <Field
                      as={TextField}
                      fullWidth
                      name="resetCode"
                      label="Reset Code"
                      type="text"
                    />
                    <ErrorMessage name="resetCode">
                      {(msg) => (
                        <Typography variant="body2" color="error">
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                    <Field
                      as={TextField}
                      fullWidth
                      name="newPassword"
                      label="New Password"
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={togglePasswordVisibility}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ErrorMessage name="newPassword">
                      {(msg) => (
                        <Typography variant="body2" color="error">
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                    <Field
                      as={TextField}
                      fullWidth
                      name="confirmNewPassword"
                      label="Confirm New Password"
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={togglePasswordVisibility}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ErrorMessage name="confirmNewPassword">
                      {(msg) => (
                        <Typography variant="body2" color="error">
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                    {isSubmitting ? (
                      <CircularProgress />
                    ) : (
                      <Button fullWidth variant="contained" type="submit">
                        Reset Password
                      </Button>
                    )}
                  </Stack>
                </Form>
              )}
            </Formik>
          </Stack>
        </Card>
      </Box>
    </Box>
  );
}

export default ResetPassword;
