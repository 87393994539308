import React from "react";
import ReactDOM from "react-dom/client";
import Main from "./Main";
import store from "./redux/store";
import { Provider, useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

store.dispatch({ type: "CHECK_TOKEN_ON_LOAD" });

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <Main />
  </Provider>
);
