import React, { useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  TextField,
  Button,
  Typography,
  Stack,
  Card,
  Link,
  CircularProgress,
  Divider,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import axios from "axios"; // Import axios for making HTTP requests

import logo from "../../images/newleey_text_logo.webp";

function PasswordRecovery(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState(""); // State to hold the email entered by the user
  const [loading, setLoading] = useState(false); // State to manage loading state

  const handleEmailChange = (e) => {
    setEmail(e.target.value); // Update the email state as the user types
  };

  const handlePasswordRecovery = async () => {
    try {
      setLoading(true); // Set loading state to true while making the request
      const response = await axios.post(
        "https://services.newleey.com/api/user/password-reset/request",
        {
          email,
        }
      ); // Make POST request to password recovery endpoint
      setLoading(false); // Set loading state to false after the request is completed
      console.log(response.data); // Log the response from the server
      // Handle success, display appropriate message to the user
      alert(
        "If your email exists in our system, you will receive a password reset link. Please check your email. You will be redirected to the reset password page."
      );
      navigate("/reset-password"); // Navigate the user to the reset password page ("/reset-password
    } catch (error) {
      setLoading(false); // Set loading state to false if there's an error
      console.error(error); // Log any errors to the console
      // Handle error, display appropriate message to the user
      alert("An error occurred. Please try again later.");
    }
  };

  return (
    <Box
      sx={{
        flex: "1 1 auto",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        height: "98vh",
        backgroundColor: "#2D3DFF",
      }}
    >
      <Box
        sx={{
          maxWidth: 650,
          px: 3,
          py: "100px",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={logo} alt="Newleey Logo" style={{ width: "60%" }} />
        </Box>
        <Card
          sx={{
            p: 3,
            mt: "60px",
          }}
        >
          <Stack spacing={1} sx={{ mb: 3, alignItems: "center", gap: 2 }}>
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              Forgot Your Password?
            </Typography>
            <Typography>
              Enter your email address below and we'll send you a link to reset
              your password.
            </Typography>
            <TextField
              fullWidth
              label="Email Address"
              name="email"
              type="email"
              value={email} // Bind the email state to the input value
              onChange={handleEmailChange} // Handle changes in the email input
            />

            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                fullWidth
                variant="contained"
                onClick={handlePasswordRecovery} // Call the function to handle password recovery
              >
                Send Password Reset Link
              </Button>
            )}
          </Stack>
        </Card>
      </Box>
    </Box>
  );
}

export default PasswordRecovery;
